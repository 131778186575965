<script>
const i18nQuestion = 'QUESTIONS'

export default {
    data() {
        return {
            additionalTypes: [
                {
                    text: this.typeToText('null'),
                    value: null,
                },
                {
                    text: this.typeToText('number'),
                    value: 'number',
                },
                {
                    text: this.typeToText('product_price'),
                    value: 'product_price',
                },
                {
                    text: this.typeToText('date'),
                    value: 'date',
                },
                {
                    text: this.typeToText('text'),
                    value: 'text',
                },
                {
                    text: this.typeToText('file'),
                    value: 'file',
                },
            ],
            types: [
                {
                    text: this.typeToText('number'),
                    value: 'number',
                },
                {
                    text: this.typeToText('date'),
                    value: 'date',
                },
                {
                    text: this.typeToText('choice'),
                    value: 'choice',
                },
                {
                    text: this.typeToText('text'),
                    value: 'text',
                },
                {
                    text: this.typeToText('file'),
                    value: 'file',
                },
                {
                    text: this.typeToText('boolean'),
                    value: 'boolean',
                },
            ],
            subtypes: {
                text: [
                    {
                        text: this.subtypeToText('null'),
                        value: null,
                    },
                    {
                        text: this.subtypeToText('barcode_or_qrcode'),
                        value: 'barcode_or_qrcode',
                    },
                    {
                        text: this.subtypeToText('phone'),
                        value: 'phone',
                    },
                    {
                        text: this.subtypeToText('numeric_text'),
                        value: 'numeric_text',
                    },
                ],
                date: [
                    {
                        text: this.subtypeToText('null'),
                        value: null,
                    },
                    {
                        text: this.subtypeToText('due_date'),
                        value: 'due_date',
                    },
                ],
                choice: [
                    {
                        text: this.subtypeToText('null'),
                        value: null,
                    },
                    {
                        text: this.subtypeToText('types_of_additional_point'),
                        value: 'types_of_additional_point',
                    },
                    {
                        text: this.subtypeToText('types_of_share'),
                        value: 'types_of_share',
                    },
                ],
                number: [
                    {
                        text: this.subtypeToText('null'),
                        value: null,
                    },
                    {
                        text: this.subtypeToText('product_price'),
                        value: 'product_price',
                    },
                    {
                        text: this.subtypeToText('shared_gondola'),
                        value: 'shared_gondola',
                    },
                    {
                        text: this.subtypeToText('due_date_quantity'),
                        value: 'due_date_quantity',
                    },
                    {
                        text: this.subtypeToText('additional_point_quantity'),
                        value: 'additional_point_quantity',
                    },
                ],
                boolean: [
                    {
                        text: this.subtypeToText('null'),
                        value: null,
                    },
                    {
                        text: this.subtypeToText('before_out_of_stock'),
                        value: 'before_out_of_stock',
                    },
                    {
                        text: this.subtypeToText('out_of_stock'),
                        value: 'out_of_stock',
                    },
                    {
                        text: this.subtypeToText('near_due_date'),
                        value: 'near_due_date',
                    },
                    {
                        text: this.subtypeToText('tabloid'),
                        value: 'tabloid',
                    },
                    {
                        text: this.subtypeToText('additional_point'),
                        value: 'additional_point',
                    },
                ],
            },
        }
    },

    methods: {
        typeToText(value) {
            return this.getI18n(i18nQuestion, `TYPES.${value}`)
        },

        subtypeToText(value) {
            return this.getI18n(i18nQuestion, `SUBTYPES.${value}`)
        },
    },
}
</script>
