<script>
import { required, integer, minValue } from 'vuelidate/lib/validators'
import Treeselect from '@riophae/vue-treeselect'
import validation from '@src/mixins/validation'
import frequencies from '@src/mixins/frequencies'
import frequenciesWithoutChildrens from '@src/mixins/frequenciesWithoutChildrens'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import AddButton from '@/src/components/AddButton'

export default {
    components: {
        Treeselect,
        DataModeVuetable,
        AddButton,
    },
    mixins: [frequencies, frequenciesWithoutChildrens, validation],
    props: {
        value: {
            type: Array,
            required: true,
        },
        listClass: {
            type: String,
            default: '',
        },
        listInvalidFeedback: {
            type: String,
            default: '',
        },
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            required: false,
            type: Boolean,
        },
    },
    validations: {
        recurrenceItem: {
            human_readable_frequencies: {
                required,
            },
            recurrence: {
                required,
                integer,
                minValue: minValue(0),
            },
        },
    },
    data() {
        return {
            recurrenceItem: {
                recurrence: 0,
                human_readable_frequencies: [],
            },
            perPage: 100,
            addItemVisible: true,
            valueConsistsOf: 'LEAF_PRIORITY',
            fields: [
                {
                    key: 'human_readable_frequencies',
                    label: this.getI18n('COMMON', 'frequency'),
                    sortable: true,
                    formatter: this.formatFrequency,
                },
                {
                    key: 'recurrence',
                    label: this.getI18n('COMMON', 'recurrence'),
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
        }
    },
    computed: {
        i18nConflictingInvalid() {
            return this.getI18n('ERROR_CODES', 'invalid_frequency')
        },
        i18nInvalidRecurrence() {
            return this.getI18n('ERROR_CODES', 'invalid_recurrence')
        },
        i18nFrequency() {
            return this.getI18n('COMMON', 'frequency')
        },
        i18nAdd() {
            return this.getI18n('COMMON', 'add')
        },
        i18nItems() {
            return this.getI18nModified({
                prefix: 'COMMON',
                suffix: 'item',
                modifier: 2,
            })
        },
        i18nRecurrence() {
            return this.getI18nModified({
                prefix: 'COMMON',
                suffix: 'recurrence',
                modifier: 2,
            })
        },
        i18nInvalidPosRestriction() {
            return this.getI18n('ERROR_CODES', 'invalid_restriction')
        },
        recurrenceItemValidation() {
            if (
                this.$v.recurrenceItem.$dirty ||
                this.$v.recurrenceItem.$error
            ) {
                return !this.$v.recurrenceItem.$error
            }
            return null
        },
        items: {
            deep: true,
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
    methods: {
        formatFrequency(value) {
            let frequencyFormatted = ''
            if (Array.isArray(value)) {
                value.forEach((element) => {
                    const frequency = this.frequenciesWithoutChildrens.find(
                        (frequency) => element === frequency.id
                    )
                    const separator = frequencyFormatted.length > 0 ? ', ' : ''
                    frequencyFormatted = `${frequencyFormatted}${separator}${frequency.label}`
                })
            }
            return frequencyFormatted
        },
        cleanItemText() {
            this.recurrenceItem = {
                recurrence: 0,
                human_readable_frequencies: []
            }
            this.$nextTick(() => this.$v.recurrenceItem.$reset())
        },
        addItem() {
            this.$v.recurrenceItem.$touch()
            if (!this.recurrenceItemValidation) {
                return
            }

            this.items.push({
                id: this.items.length + 1,
                recurrence: this.recurrenceItem.recurrence,
                human_readable_frequencies: this.recurrenceItem.human_readable_frequencies,
                restricted_by_point_of_sale: true
            })

            this.cleanItemText()
        },
        async handleRemoveItem(id) {
            const index = this.items.map((e) => e.id).indexOf(id)

            if (index > -1) {
                this.items.splice(index, 1)
            }
        },
    },
}
</script>
<template>
    <div class="col-md-12">
        <b-row class="mt-3">
            <b-col v-if="!disabled" class="col-sm-12 col-md-4">
                <b-form-group
                    :label="i18nFrequency"
                    class="required label-pdv"
                    label-for="itemText"
                    :invalid-feedback="i18nConflictingInvalid"
                >
                    <treeselect
                        id="filter-frequency"
                        v-model="recurrenceItem.human_readable_frequencies"
                        :class="validationClass($v.recurrenceItem.human_readable_frequencies)"
                        :multiple="true"
                        :options="frequencies"
                        :value-consists-of="valueConsistsOf"
                        :placeholder="i18nFrequency"
                        @input="$v.recurrenceItem.human_readable_frequencies.$touch;"
                    />
                </b-form-group>
                <b-form-group
                    :label="i18nRecurrence"
                    class="label-pdv"
                    label-for="itemText"
                    :invalid-feedback="i18nInvalidRecurrence"
                >
                    <b-form-input
                        v-model.trim="$v.recurrenceItem.recurrence.$model"
                        type="number"
                        :class="validationClass($v.recurrenceItem.recurrence)"
                        class="input-pdv-blue"
                        min="0"
                    />
                </b-form-group>
                <add-button
                    ref="addButton"
                    @onClick="addItem"
                />
            </b-col>
            <div :class="disabled ? 'col-sm-12 col-md-12' : 'col-sm-12 col-md-8'">
                <data-mode-vuetable
                    ref="relationVuetable"
                    v-model="items"
                    :fields="fields"
                    :display-delete-button="!disabled"
                    :busy="busy"
                    @onClickDelete="handleRemoveItem"
                />
            </div>
        </b-row>
        <b-form-invalid-feedback id="itemTextFeedBack">
            {{ listInvalidFeedback }}
        </b-form-invalid-feedback>
    </div>
</template>
