<script>
import answerTypes from '@src/mixins/answerTypes'

export default {
    mixins: [answerTypes],
    data() {
        return {
            choiceChildrenFields: [
                {
                    key: 'label',
                    label: this.getI18n('QUESTIONS', 'FIELDS.choice'),
                    sortable: true,
                },
                {
                    key: 'additional_type',
                    label: this.getI18n('QUESTIONS', 'FIELDS.subtype'),
                    formatter: (value) => {
                        return this.typeToText(value)
                    },
                },
            ],
        }
    },
}
</script>
