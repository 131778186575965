<template>
    <VuePerfectScrollbar
        id="questionnaire-item-choices"
        class="scrollList"
        style="max-height: 300px"
        aria-describedby="itemTextFeedBack"
    >
        <div v-if="busy">
            <div
                v-for="(index) in 4" :key="index"
                class="list-group-item"
            >
                <div v-if="showSelect">
                    <b-skeleton width="50%" />
                    <b-skeleton type="button" width="100%" />
                </div>
                <div v-else>
                    <b-skeleton width="50%" />
                </div>
            </div>
        </div>
        <div
            v-for="(choice, index) in choices"
            v-else
            :key="index"
            class="list-group-item"
        >
            <label class="label-pdv">
                {{ choice.label }}
            </label>
            <b-form-select
                v-if="showSelect"
                v-model="selectedItems[findSelectedIndex(choice.id)].next_id"
                class="input-pdv-blue"
                :options="filterSameItem()"
                value-field="id"
                text-field="label"
                :disabled="disabled"
            />
        </div>
    </VuePerfectScrollbar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    components: {
        VuePerfectScrollbar,
    },
    mixins: ['swalFeedback'],
    props: {
        showSelect: {
            required: true,
            type: Boolean,
        },
        value: {
            type: Array,
            required: true,
        },
        questionnaireItems: {
            type: Array,
            required: true,
        },
        choices: {
            type: Array,
            required: true,
        },
        questionnaireItemId: {
            type: Number,
            required: true,
        },
        propertyName: {
            type: String,
            required: true,
        },
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            itemText: '',
            items: [
                { id: 0, label: this.getI18n('ACTION', 'continue') },
                {
                    id: null,
                    label: this.getI18n('ACTION', 'end_questionnaire'),
                },
                ...this.questionnaireItems,
            ],
            selectedItems: this.getSelectedItems(),
        }
    },
    computed: {
        fluxChoices: {
            deep: true,
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        selectedItems: {
            deep: true,
            handler(val) {
                this.fluxChoices = val
            },
        },
        choices() {
            this.selectedItems = this.getSelectedItems()
        },
    },
    methods: {
        getSelectedItems() {
            return this.choices.map((choice) => {
                const index = this.value.findIndex(
                    (el) => el[this.propertyName] === choice.id
                )
                if (index > -1) {
                    return this.value[index]
                }
                return {
                    [this.propertyName]: choice.id,
                    next_id: 0,
                }
            })
        },
        filterSameItem() {
            const items = this.items.map((item) => {
                const copyItem = {
                    id: 0,
                    label: '',
                }
                Object.assign(copyItem, item)
                return copyItem
            })

            if (this.questionnaireItemId > 0) {
                return items.filter((item) => {
                    return item.id !== this.questionnaireItemId
                })
            }
            return items
        },
        findSelectedIndex(option) {
            return this.selectedItems.findIndex(
                (el) => el[this.propertyName] === option
            )
        },
    },
}
</script>
