<template>
  <treeSelectWithService
    ref="product-category-restrictions"
    v-model="selectedProductsCategories"
    :option="{ id: null, name: '' }"
    :async-search="false"
    :parameters="parameters"
    :service="'product-categories'"
    :value-consists-of="'LEAF_PRIORITY'"
    :multiple="true"
    :invalid-feedback="getI18n('ERROR_CODES.invalid_category')"
    :label="i18nProductCategory"
    :no-children-text="getI18n('PRODUCT_CATEGORIES.INFO', 'no_subcategories_text')"
    :disabled="busy || disabled"
  />
</template>

<script>
import filterVuetable from '@src/mixins/filterVuetable'
import treeSelectWithService from "@components/treeSelectWithService";

export default {
  components: { treeSelectWithService },
  mixins: [filterVuetable],
  props: {
    value: {
      type: Array,
      required: true,
    },
    busy: {
        type: Boolean,
        required: false,
    },
    disabled: {
        type: Boolean,
        required: false,
    },
  },
  data() {
    return {
      parameters: this.getParameters(),
      productCategories: this.value.map((productCategory) => productCategory.id),
      search: '',
    }
  },
  computed: {
    selectedProductsCategories: {
      get() {
        return this.productCategories
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
    i18nProductCategory() {
      return this.getI18n('COMMON', 'category')
    },
  },
  methods: {
    getParameters() {
      const search = this.createSearch({
        name: this.search,
      })
      return this.formatParameters({
        per_page: 1000,
        search,
        searchJoin: search.search(';') > 0 ? 'and' : '',
      })
    },
    filterAndReload() {
      this.parameters = this.getParameters()
    },
  },
}
</script>

<style>
</style>
