<script>

export default {
    data() {
        return {
            conditionChildrenActionFields: [
                {
                    key: 'question',
                    label: this.getI18n('QUESTIONS', 'TITLES.question'),
                    sortable: true,
                },
                {
                    key: 'choice',
                    label: this.getI18n('QUESTIONS', 'FIELDS.choice'),
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
        }
    },
}
</script>
