<script>
export default {
    data() {
        return {
            questionnaireTypes: [
                {
                    value: 'generic',
                    text: this.getI18n('QUESTIONNAIRES', 'TYPES.generic'),
                },
                {
                    value: 'product',
                    text: this.getI18n('QUESTIONNAIRES', 'TYPES.product'),
                },
                {
                    value: 'product_category',
                    text: this.getI18n('QUESTIONNAIRES', 'TYPES.product_category'),
                },
                {
                    value: 'new_pos',
                    text: this.getI18n('QUESTIONNAIRES', 'TYPES.new_pos'),
                },
                // {
                //     value: 'out_of_stock',
                //     text: this.getI18n('QUESTIONNAIRES', 'TYPES.out_of_stock'),
                // },
            ],
        }
    },
    methods: {
        typeToText(value) {
            return this.getI18n('QUESTIONNAIRES', `TYPES.${value}`)
        },
    },
}
</script>
