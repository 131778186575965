import BaseService from '@src/services/BaseService'

class QuestionnaireItemsService extends BaseService {
    constructor() {
        super('questionnaire-items')
    }

    syncFluxChoices(id, choices) {
        return this.axios.post(`${this.getUrl()}/${id}/sync-flux-choices`, choices)
    }

    syncFluxBooleans(id, booleansChoices) {
        return this.axios.post(
            `${this.getUrl()}/${id}/sync-flux-booleans`,
            booleansChoices
        )
    }

    syncPositions(items) {
        return this.axios.post(`${this.getUrl()}/sync-positions`, items)
    }
}

export default new QuestionnaireItemsService()
