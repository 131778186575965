<script>

export default {
    data() {
        return {
            roleChildrenActionFields: [
                {
                    key: 'name',
                    label: this.getI18n('COMMON.role_name'),
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
        }
    },
}
</script>
