import BaseService from '@src/services/BaseService'

class QuestionsService extends BaseService {
    constructor() {
        super('questions')
    }

    syncChoices(id, choices) {
        return this.axios.post(`${this.getUrl()}/${id}/sync-choices`, { choices })
    }
}

export default new QuestionsService()
