<template>
    <form-modal
        ref="formModal"
        :handle-open="resetValidation"
        :v="$v.modalQuestionnaireItem"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="lg"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nQuestionnaireItem"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'QUESTIONNAIREITEM')"
                                :title="getI18n('QUESTIONS', 'TITLES.question')"
                                :disabled="deleteLoading"
                                @onClick="isEdit = true"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'QUESTIONNAIREITEM')"
                                :title="getI18n('QUESTIONS', 'TITLES.question')"
                                :busy="deleteLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <!--<b-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nName"
                            class="required label-pdv"
                            label-for="modal-label"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_questionnaire')"
                        >
                            <b-form-input
                                id="modal-label"
                                v-model.trim="$v.modalQuestionnaireItem.label.$model"
                                name="label"
                                class="input-pdv-blue"
                                aria-describedby="input-1-live-feedback"
                                type="text"
                                :state="validateField('label','modalQuestionnaireItem')"
                                :placeholder="i18nName"
                                :disabled="!isEdit || (isQuestionnaireItemCreated && modalQuestionnaireItem.hasAnswers)"
                                @blur="$v.modalQuestionnaireItem.label.$touch"
                                @input="clearResponseError('label', 'modalQuestionnaireItem')"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>-->
                <b-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nQuestion"
                            class="required label-pdv"
                            label-for="modal-type"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_question')"
                        >
                            <multiSelectWithService
                                :id="'modal-question'"
                                ref="questionMultiselect"
                                v-model="modalQuestionnaireItem.question"
                                :input-function="$v.modalQuestionnaireItem.question_id.$touch"
                                :select-class="validationClass($v.modalQuestionnaireItem.question_id)"
                                :service="'questions'"
                                :parameters="questionParameters"
                                label="question"
                                :searchable="true"
                                :multiple="false"
                                :disabled="!isEdit || isQuestionnaireItemCreated"
                                :placeholder="i18nQuestion"
                                @input="changeQuestionId"
                            ></multiSelectWithService>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="modalQuestionnaireItem.question.type === 'choice'">
                    <b-col md="12">
                        <b-form-group
                            :label="i18nChoices"
                            class="label-pdv"
                            label-for="questionnaire-item-choices"
                        >
                            <questionnaire-item-choices
                                id="questionnaire-item-choices"
                                ref="questionnaireItemChoices"
                                v-model="fluxChoices"
                                :show-select="!modalQuestionnaireItem.question.is_multiple"
                                :questionnaire-items="questionnaireItems"
                                :questionnaire-item-id="modalQuestionnaireItem.id"
                                :choices="modalQuestionnaireItem.choices"
                                :property-name="'choice_option_id'"
                                :busy="loadingChoices"
                                :disabled="!isEdit"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-else-if="modalQuestionnaireItem.question.type === 'boolean'">
                    <b-col md="12">
                        <b-form-group
                            :label="i18nChoices"
                            class="label-pdv"
                            label-for="questionnaire-item-booleans"
                        >
                            <questionnaire-item-choices
                                id="questionnaire-item-booleans"
                                ref="questionnaireItemBooleans"
                                v-model="fluxBooleans"
                                :show-select="true"
                                :questionnaire-items="questionnaireItems"
                                :questionnaire-item-id="modalQuestionnaireItem.id"
                                :choices="booleanChoices"
                                :property-name="'boolean'"
                                :disabled="!isEdit"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col md="6">
                        <b-form-group
                            :label="i18nMandatoryAnswer"
                            label-for="modal-is-required"
                            label-align="center"
                            class="label-pdv"
                        >
                            <b-form-checkbox
                                id="modal-is-required"
                                v-model="modalQuestionnaireItem.is_required"
                                name="is_required"
                                class="text-center"
                                switch
                                size="lg"
                                :disabled="!isEdit"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nFinalizeTask"
                            label-for="modal-is-end"
                            label-align="center"
                            class="label-pdv"
                        >
                            <b-form-checkbox
                                id="modal-is-end"
                                v-model="modalQuestionnaireItem.is_end"
                                name="is_end"
                                class="text-center"
                                switch
                                size="lg"
                                :disabled="!isEdit"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="isQuestionnaireItemCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@components/FormModal'
import {
    required,
    minLength,
    maxLength,
    numeric,
} from 'vuelidate/lib/validators'

import multiSelectWithService from '@src/components/multiSelectWithService'
import QuestionnaireItemsService from '@src/services/QuestionnaireItemsService.js'
import validationMixin from '@src/mixins/validation'
import swalFeedback from '@src/mixins/swalFeedback'
import questionnaireItemChoices from '@src/router/views/questionnaires/questionnaireItemChoices'
import QuestionsService from '@/src/services/QuestionsService'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'

const i18nCommon = 'COMMON'
const i18nKey = 'QUESTIONNAIRE_ITEMS'

export default {
    components: {
        FormModal,
        multiSelectWithService,
        questionnaireItemChoices,
        ConfirmationFormModal,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
    },
    mixins: [validationMixin, swalFeedback],
    props: {
        modal: {
            type: Object,
            default: () => {
                return {
                    questionnaire_id: null,
                    question_id: null,
                    question: {
                        id: null,
                        type: '',
                        is_multiple: null,
                        choices: [],
                    },
                    previous_id: null,
                    next_id: null,
                    position: 0,
                    label: '',
                    is_required: true,
                    is_end: false,
                    hasAnswers: null,
                    choices: [],
                    flux_choices: [],
                    flux_booleans: [],
                }
            },
        },
        questionnaireItems: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalQuestionnaireItem: this.modal,
            fluxChoices: [],
            fluxBooleans: [],
            booleanChoices: [
                { id: false, label: this.getI18n('COMMON', 'false') },
                { id: true, label: this.getI18n('COMMON', 'true') },
            ],
            isEdit: false,
            submitLoading: false,
            deleteLoading: false,
            types: ['generic', 'product', 'product_category'],
            questionParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'question',
                sortedBy: 'asc',
            },
            loadingChoices: false,
        }
    },
    validations: {
        modalQuestionnaireItem: {
            label: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
            question_id: {
                required,
                numeric,
            },
        },
    },

    computed: {
        isQuestionnaireItemCreated() {
            return this.modalQuestionnaireItem.id > 0
        },
        i18nTitleModal() {
            if (this.isQuestionnaireItemCreated) {
                if (this.isEdit) {
                    return this.getI18n(i18nKey, 'TITLES.edit_questionnaire_item')
                }
                return this.i18nQuestionnaireItem
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_questionnaire_item')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nFinalizeTask() {
            return this.getI18n(i18nKey, 'FIELDS.finalize_task')
        },
        i18nMandatoryAnswer() {
            return this.getI18n(i18nKey, 'FIELDS.mandatory_answer')
        },
        i18nQuestionnaireItem() {
            return this.getI18n(i18nKey, 'TITLES.questionnaire_item')
        },
        i18nQuestion() {
            return this.getI18n('QUESTIONS', 'TITLES.question')
        },
        i18nQuestionnaireItems() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.questionnaire',
                modifier: 2,
            })
        },
        i18nChoices() {
            return this.getI18nModified({
                        prefix: 'QUESTIONS',
                        suffix: 'TYPES.choice',
                        modifier: 2,
                    })
        },
        modalOperation() {
            return this.isQuestionnaireItemCreated ? 'edited' : 'created'
        },
    },

    methods: {
        async changeQuestionId(question) {
            if (question.type === 'choice') {
                this.loadingChoices = true
                const response = await QuestionsService.fetchById(question.id, {
                    per_page: 1000,
                    page: 1,
                    orderBy: 'question',
                    with: ['choices'],
                    sortedBy: 'asc',
                })
                    .then((response) => response)
                    .catch((err) => err)

                if (response.status.toString().charAt(0) === '2') {
                    this.modalQuestionnaireItem.question = response.data.data
                    this.modalQuestionnaireItem.question_id = response.data.data.id
                    this.modalQuestionnaireItem.label = response.data.data.question
                    this.modalQuestionnaireItem.choices = response.data.data.choices
                    this.clearResponseError('question_id', 'modalQuestionnaireItem')
                    this.$nextTick(() => {
                        this.loadingChoices = false
                    })
                }
            } else {
                this.modalQuestionnaireItem.question_id = question ? question.id : null
                this.modalQuestionnaireItem.label = question ? question.question : ''
                this.clearResponseError('question_id', 'modalQuestionnaireItem')
            }
        },

        typeLabel(value) {
            return `${this.getI18n(i18nKey, 'TYPES.' + value)}`
        },
        showModal(isEdit) {
            this.isEdit = isEdit
            this.submitLoading = false
            this.deleteLoading = false
            // Using JSON functions to copy by value and not reference
            this.fluxChoices = this.modalQuestionnaireItem.flux_choices.map(
                (fluxChoice) => {
                    return {
                        questionnaire_item_id: fluxChoice.questionnaire_item_id,
                        choice_option_id: fluxChoice.choice_option_id,
                        next_id: fluxChoice.next_id,
                    }
                }
            )

            this.fluxBooleans = this.modalQuestionnaireItem.flux_booleans.map(
                (fluxBoolean) => {
                    return {
                        questionnaire_item_id:
                            fluxBoolean.questionnaire_item_id,
                        boolean: fluxBoolean.boolean,
                        next_id: fluxBoolean.next_id,
                    }
                }
            )

            this.$nextTick(() => this.$refs.formModal.show())
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        cleanModal() {
            this.replaceModalData({
                question_id: null,
                question: {
                    id: null,
                    type: '',
                    choices: [],
                },
                previous_id: null,
                next_id: null,
                position: 0,
                label: '',
                is_required: true,
                is_end: false,
                hasAnswers: null,
                choices: [],
                flux_choices: [],
                flux_booleans: [],
            })
            this.resetValidation()
        },

        replaceModalData(data) {
            Object.assign(this.modalQuestionnaireItem, data)
        },

        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        handleOk() {
            this.$v.modalQuestionnaireItem.$touch()
            if (!this.$v.modalQuestionnaireItem.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit, null, true)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            if (this.questionnaireItems.length > 0 && !this.isQuestionnaireItemCreated) {
                this.modalQuestionnaireItem.previous_id = this.questionnaireItems[
                    this.questionnaireItems.length - 1
                ].id

                this.modalQuestionnaireItem.position =
                    this.questionnaireItems.length + 1
            }
            const operation = this.isQuestionnaireItemCreated
                ? QuestionnaireItemsService.update(
                      this.modalQuestionnaireItem.id,
                      this.modalQuestionnaireItem
                  )
                : QuestionnaireItemsService.create(this.modalQuestionnaireItem)

            const createResponse = await operation
                .then((response) => response)
                .catch((error) => {
                    this.negativeFeedback()
                    return error
                })

            const statusCode = createResponse.status.toString()

            if (statusCode.charAt(0) === '2') {
                const fluxChoices = this.fluxChoices.filter(
                    (el) => el.next_id !== 0
                )
                const fluxBooleans = this.fluxBooleans.filter(
                    (el) => el.next_id !== 0
                )

                if (this.modalQuestionnaireItem.question.type === 'choice') {
                    await QuestionnaireItemsService.syncFluxChoices(
                        createResponse.data.data.id,
                        {
                            flux_choices: fluxChoices,
                        }
                    )
                }
                if (this.modalQuestionnaireItem.question.type === 'boolean') {
                    await QuestionnaireItemsService.syncFluxBooleans(
                        createResponse.data.data.id,
                        {
                            flux_booleans: fluxBooleans,
                        }
                    )
                }

                this.$emit('refresh')
                this.resetValidation()
                if (submit === 'create_another') {
                    this.cleanModal()
                }
                else {
                    this.$refs.formModal.hide()
                }
                this.positiveFeedback(this.i18nQuestionnaireItem, this.modalOperation)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = createResponse.data.errors
            }

            this.submitLoading = false
        },
        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteQuestionnarieItem)
        },
        async deleteQuestionnarieItem(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.modalQuestionnaireItem.$touch()
            this.deleteLoading = true
            const response = await QuestionnaireItemsService.delete(this.modalQuestionnaireItem.id);

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nQuestionnaireItem, 'deleted', 3)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }

            this.deleteLoading = false
        },
    },
}
</script>
