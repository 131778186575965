<template>
    <form-modal
        ref="formModal"
        :handle-open="resetValidation"
        :v="$v.modalQuestionnaireEmail"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nQuestionnaireEmail"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nQuestionnaireEmail"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'QUESTIONNAIREFILTER')"
                                :title="getI18n('COMMON', 'email')"
                                :busy="editLoading"
                                :disabled="deleteLoading"
                                @onClick="handleEdit"
                            />
                            <delete-label-button
                                v-if="$can('DELETE', 'QUESTIONNAIREFILTER')"
                                :title="getI18n('COMMON', 'email')"
                                :busy="deleteLoading"
                                :disabled="editLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-tabs
                    id="questionnaire-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv">
                    <b-tab :title="i18nInformation">
                        <b-form-row>
                            <b-col md="6">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'FIELDS.subject')"
                                    class="required label-pdv"
                                    label-for="subject-input"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_subject')"
                                >
                                    <b-form-input
                                        id="subject-input"
                                        v-model.trim="$v.modalQuestionnaireEmail.subject.$model"
                                        class="input-pdv-blue"
                                        name="subject"
                                        type="text"
                                        :state="validateField('subject', 'modalQuestionnaireEmail')"
                                        :disabled="!isEdit || submitLoading"
                                        autofocus
                                        autocomplete="off"
                                        @input="clearResponseError('subject', 'modalQuestionnaireEmail')"
                                        @blur="$v.modalQuestionnaireEmail.subject.$touch"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'FIELDS.recipient')"
                                    class="required label-pdv"
                                    label-for="recipient-input"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_email')"
                                >
                                    <b-form-input
                                        id="subject-input"
                                        v-model.trim="$v.modalQuestionnaireEmail.recipient.$model"
                                        class="input-pdv-blue"
                                        name="recipient"
                                        type="text"
                                        :state="validateField('recipient', 'modalQuestionnaireEmail')"
                                        :disabled="!isEdit || submitLoading"
                                        autofocus
                                        autocomplete="off"
                                        @input="clearResponseError('recipient', 'modalQuestionnaireEmail')"
                                        @blur="$v.modalQuestionnaireEmail.recipient.$touch"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nConditions"
                                    label-for="add-conditions-input"
                                    class="label-pdv" >
                                    <multiSelectWithService
                                        :id="'add-conditions-input'"
                                        ref="conditionMultiselect"
                                        v-model="questionCondition"
                                        label="label"
                                        :service="'questionnaire-items'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="submitLoading"
                                        :parameters="conditionsParameters"
                                    />
                                </b-form-group>
                                <b-form-group
                                    :label="i18nConditionAnswer"
                                    label-for="add-condition-answer-input"
                                    class="label-pdv" >
                                    <multi-select
                                        :id="'add-condition-answer-input'"
                                        ref="conditionAnswerMultiselect"
                                        v-model="questionAnswerCondition"
                                        label="label"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="submitLoading"
                                        :options="questionCondition?.choices"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <label v-if="!isEdit" class="d-block label-pdv">Condições</label>
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="modalQuestionnaireEmail.conditions"
                                    :fields="conditionChildrenActionFields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    small
                                    @onClickDelete="handleRemoveCondition"
                                />
                            </b-col>
                        </b-form-row>
                    </b-tab>

                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="isQuestionnaireEmailCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import {
    required,
    email,
    minLength,
} from 'vuelidate/lib/validators'

import swalFeedback from '@src/mixins/swalFeedback'

import QuestionnaireEmailsService from '@src/services/QuestionnaireEmailsService.js'

import validationMixin from '@src/mixins/validation'

import FormModal from '@components/FormModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import DataModeVuetable from "@components/DataModeVuetable.vue";
import multiSelectWithService from "@components/multiSelectWithService.vue";
import conditionChildrenActionFields from '@src/fields/childrens/conditionChildrenActionFields'
import MultiSelect from "@components/multiSelect.vue";

const i18nCommon = 'COMMON'
const i18nKey = 'QUESTIONNAIRE_EMAILS'

export default {
    components: {
        MultiSelect,
        multiSelectWithService, DataModeVuetable,
        ConfirmationFormModal,
        FeedbackModal,
        FormModal,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        conditionChildrenActionFields,
    ],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        modal: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    questionnaire_id: 0,
                    subject: '',
                    recipient: '',
                    conditions: [],
                }
            },
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalQuestionnaireEmail: this.modal,
            isEdit: false,
            questionCondition: '',
            questionAnswerCondition: '',
            editLoading: false,
            submitLoading: false,
            deleteLoading: false,
            tabIndex: 0,
            conditionsParameters: {
                search: `questionnaire_id:${this.modal.questionnaire_id}`,
                check_for_answers: false,
                per_page: 1000,
                page: 1,
                orderBy: 'position',
                sortedBy: 'asc',
                with: ['choices']
            },
        }
    },
    validations: {
        modalQuestionnaireEmail: {
            subject: {
                required,
                minLength: minLength(1),
            },

            recipient: {
                required,
                email,
            },
        },
    },

    computed: {
        isQuestionnaireEmailCreated() {
            return this.modalQuestionnaireEmail.id > 0
        },
        i18nTitleModal() {
            if (this.isQuestionnaireEmailCreated) {
                if (this.isEdit) {
                    return this.getI18n(i18nKey, 'TITLES.edit_questionnaire_email')
                }
                return this.i18nQuestionnaireEmail
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_questionnaire_email')
            }
        },
        i18nQuestionnaireEmail() {
            return this.getI18n(i18nKey, 'TITLES.questionnaire_email')
        },
        i18nConditions() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.conditions',
                modifier: 2,
            })
        },
        i18nConditionAnswer() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'FIELDS.conditionAnswer',
                modifier: 2,
            })
        },
        modalOperation() {
            return this.isQuestionnaireEmailCreated ? 'edited' : 'created'
        },
        i18nSuccessfullyProcessed() {
            return this.getI18nModified({
                prefix: 'RESPONSES',
                suffix: 'successfully_processed',
                modifier: 3,
            })
        },
        i18nNoRecordsFound() {
            return this.getI18n('PAGINATION', 'no_records_found')
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'info',
                modifier: 2,
            })
        },
    },

    watch: {
        questionCondition(value) {
            if (value) {
                this.$refs.conditionAnswerMultiselect.$emit('options', value.choices)
            }
        },
        questionAnswerCondition(value) {
            if (value) {
                const condition = {
                    choice_id: value.id,
                    question_id: this.questionCondition.question_id,
                    question: this.questionCondition.label,
                    choice: value.label
                };
                console.log(condition);
                const index = this.modalQuestionnaireEmail.conditions.findIndex(find => find.choice_id === condition.choice_id);
                if (index < 0) {
                    this.modalQuestionnaireEmail.conditions.push(condition)
                }
                this.questionCondition = ''
                this.questionAnswerCondition = ''
                this.$refs.conditionMultiselect.$el.focus()
            }
        }
    },

    methods: {

        showModal(isEdit) {
            this.isEdit = isEdit
            this.editLoading = false
            this.submitLoading = false
            this.deleteLoading = false
            this.tabIndex = 0
            this.$nextTick(() => this.$refs.formModal.show())
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        cleanModal() {
            this.replaceModalData({
                subject: '',
                recipient: '',
                conditions: [],
            })
            this.resetValidation()
        },

        replaceModalData(data) {
            Object.assign(this.modalQuestionnaireEmail, data)
        },

        handleEdit() {
            this.editLoading = true

            this.isEdit = true

            this.editLoading = false
        },

        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },

        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },

        handleOk() {
            this.$v.modalQuestionnaireEmail.$touch()
            if (!this.$v.modalQuestionnaireEmail.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },

        handleRemoveCondition(id) {
            const index = this.modalQuestionnaireEmail.conditions.findIndex(find => find.id === id);

            if (index > -1) {
                this.modalQuestionnaireEmail.conditions.splice(index, 1);
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            const localModal = {}
            Object.assign(localModal, this.modalQuestionnaireEmail)

            const modalInfo = {
                ...localModal,
                conditions: this.modalQuestionnaireEmail.conditions.map((el) => el),
            }

            const operation = this.isQuestionnaireEmailCreated
                ? QuestionnaireEmailsService.update(modalInfo.id, modalInfo)
                : QuestionnaireEmailsService.create(modalInfo)

            const createResponse = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = createResponse.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.successfulOperation()
            }

            if (statusCode.charAt(0) === '4') {
                this.unsuccessfulOperation()
            }

            if (statusCode === '422') {
                this.modalResponseErrors = createResponse.data.errors
                this.warningFeedbackApi(this.i18nQuestionnaireEmail, createResponse.data.errors)
            }

            this.submitLoading = false
        },

        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteQuestionnarieEmail)
        },

        async deleteQuestionnarieEmail(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.modalQuestionnaireEmail.$touch()
            this.deleteLoading = true
            const response = await QuestionnaireEmailsService.delete(this.modalQuestionnaireEmail.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nQuestionnaireEmail, 'deleted', 3)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },

        successfulOperation() {
            this.$emit('refresh')
            this.resetValidation()
            this.$refs.formModal.hide()
            this.positiveFeedback(this.i18nQuestionnaireEmail, this.modalOperation)
        },

        unsuccessfulOperation() {
            this.negativeFeedback()
        }
    },
}
</script>
