<template>
    <b-form-group
        class="required"
        :invalid-feedback="getI18n('ERROR_CODES.invalid_item')"
    >
        <b-row v-if="!disabled" class="mb-2">
            <div class="col-sm-6 form-inline">
            </div>
            <div class="col-sm-6">
                <div class="text-sm-right">
                    <add-button
                        v-if="$can('CREATE', 'QUESTIONNAIREFILTER')"
                        :title="i18nNewQuestionnaireFilter"
                        :disabled="busy"
                        @onClick="createFilter"
                    />
                </div>
            </div>
        </b-row>
        <questionnaireFilterModal
            ref="questionnaireItemModal"
            :modal-is-edit="questionnaireFilterIsEdit"
            :modal="modalQuestionnaireFilter"
            @refresh="refreshTable"
        />
        <api-mode-vuetable
            ref="vuetable"
            :fetch-data="fetch"
            :fields="fields"
            :per-page="perPage"
            :additional-parameters="additionalParameters"
            :no-data-template="getI18n('ERROR_CODES', 'no_filters_created')">
            <template slot="actions" slot-scope="props">
                <div v-if="!disabled" class="table-button-container">
                    <more-table-button @onClick="editFilter(props.rowData)" />
                </div>
            </template>
        </api-mode-vuetable>
    </b-form-group>
</template>

<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import questionnaireFilterModal from '@src/router/views/questionnaires/questionnaireFilterModal'
import swalFeedback from '@src/mixins/swalFeedback'
import QuestionnaireFiltersService from '@src/services/QuestionnaireFiltersService'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import Pagination from '@src/mixins/pagination.vue'
import AddButton from '@/src/components/AddLabelButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import moment from 'moment';

const i18nCommon = 'COMMON'
const i18nKey = 'QUESTIONNAIRE_FILTERS'

export default {
    components: {
        ApiModeVuetable,
        questionnaireFilterModal,
        AddButton,
        MoreTableButton,
    },
    mixins: [swalFeedback, Pagination, vuetableFormatters],
    props: {
        value: {
            type: Array,
            required: true,
        },
        questionnaireId: {
            type: Number,
            required: true,
        },
        questionnaireType: {
            type: String,
            required: true,
        },
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            perPage: 10,
            css: {},
            fields: [
                {
                    name: 'business_unit.name',
                    title: this.getI18n(
                        'BUSINESS_UNITS',
                        'TITLES.business_unit'
                    ),
                    sortField: 'business_units|business_units.name',
                },
                {
                    name: 'start_date',
                    title: this.getI18n(i18nKey, 'FIELDS.start_date'),
                    sortField: 'start_date',
                    formatter: this.formatDate,
                },
                {
                    name: 'end_date',
                    title: this.getI18n(i18nKey, 'FIELDS.end_date'),
                    sortField: 'end_date',
                    formatter: this.formatDate,
                },
                {
                    name: 'has_user_restrictions',
                    title: this.getI18n(
                        i18nKey,
                        'FIELDS.has_user_restrictions'
                    ),
                    sortField: 'has_user_restrictions',
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'has_point_of_sale_restrictions',
                    title: this.getI18n(
                        i18nKey,
                        'FIELDS.has_point_of_sale_restrictions'
                    ),
                    sortField: 'has_point_of_sale_restrictions',
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'has_product_restrictions',
                    title: this.getI18n(
                        i18nKey,
                        'FIELDS.has_product_restrictions'
                    ),
                    sortField: 'has_product_restrictions',
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'has_product_categories_restrictions',
                    title: this.getI18n(
                        i18nKey,
                        'FIELDS.has_product_categories_restrictions'
                    ),
                    sortField: 'has_product_categories_restrictions',
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'is_repeatable',
                    title: this.getI18n(i18nKey, 'FIELDS.is_repeatable'),
                    sortField: 'is_repeatable',
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'is_multiple',
                    title: this.getI18n(i18nKey, 'FIELDS.is_multiple'),
                    sortField: 'is_multiple',
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'is_required',
                    title: this.getI18n(i18nKey, 'FIELDS.is_required'),
                    sortField: 'is_required',
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            additionalParameters: {
                search: `questionnaire_id:${this.questionnaireId}`,
                with: [
                    'roles',
                    'users',
                    'products',
                    'pointOfSales',
                    'businessUnit',
                    'recurrence',
                    'productsCategories',
                ],
                check_for_answers: true,
            },
            modalQuestionnaireFilter: {
                id: 0,
                questionnaire_id: this.questionnaireId,
                questionnaire_type: this.questionnaireType,
                business_unit_id: 0,
                business_unit: {
                    id: 0,
                    name: '',
                },
                has_role_restrictions: false,
                has_user_restrictions: false,
                has_product_restrictions: false,
                has_point_of_sale_restrictions: false,
                is_repeatable: false,
                is_required: false,
                is_uninterrupted: false,
                is_multiple: false,
                competitor_products: null,
                start_date: null,
                end_date: null,
                last_start_date: null,
                human_readable_frequencies: ['ANY_DAY'],
                point_of_sales: [],
                roles: [],
                users: [],
                products: [],
                product_categories: [],
                recurrenceItems: [],
            },
            loadingEditFilter: [],
            questionnaireFilterIsEdit: false,
        }
    },
    computed: {
        questionnaireFilters: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        i18nQuestionnaireFilter() {
            return this.getI18n(i18nKey, 'TITLES.questionnaire_filter')
        },
        i18nNewQuestionnaireFilter() {
            return this.getI18n(i18nKey, 'BUTTONS.new_questionnaire_filter')
        },
    },
    watch: {
        questionnaireId(newValue) {
            this.additionalParameters = {
                search: `questionnaire_id:${newValue}`,
                with: [
                    'roles',
                    'users',
                    'products',
                    'pointOfSales',
                    'businessUnit',
                    'recurrence',
                    'productsCategories',
                ],
                check_for_answers: true,
            }

            this.modalQuestionnaireFilter.questionnaire_id = newValue
        },
        questionnaireType(newValue) {
            this.modalQuestionnaireFilter.questionnaire_type = newValue
        },
    },
    methods: {
        fetch(url, params) {
            return QuestionnaireFiltersService.fetchVuetable(url, params)
        },

        refreshTable() {
            this.$nextTick(() => this.$refs.vuetable.refresh())
        },

        createFilter() {
            this.questionnaireFilterIsEdit = false
            this.cleanModal()
            this.$refs.questionnaireItemModal.showModal(true)
        },
        editFilter(data) {
            this.questionnaireFilterIsEdit = true
            this.replaceModalData(data)
            this.$refs.questionnaireItemModal.showModal(false)
        },
        cleanModal() {
            Object.assign(this.modalQuestionnaireFilter, {
                id: 0,
                questionnaire_id: this.questionnaireId,
                business_unit_id: 0,
                business_unit: {
                    id: 0,
                    name: '',
                },
                has_role_restrictions: false,
                has_user_restrictions: false,
                has_product_restrictions: false,
                has_point_of_sale_restrictions: false,
                is_repeatable: false,
                is_required: false,
                is_uninterrupted: false,
                is_multiple: false,
                competitor_products: null,
                start_date: null,
                end_date: null,
                human_readable_frequencies: ['ANY_DAY'],
                point_of_sales: [],
                roles: [],
                users: [],
                products: [],
                product_categories: [],
                recurrenceItems: [],
            })
        },
        replaceModalData(data) {
            Object.assign(this.modalQuestionnaireFilter, {
                ...data,
                last_start_date: data.start_date,
            })

            if (this.modalQuestionnaireFilter.start_date) {
                this.modalQuestionnaireFilter.start_date = moment(this.modalQuestionnaireFilter.start_date, 'YYYY-MM-DD').toDate();
            }

            if (this.modalQuestionnaireFilter.end_date) {
                this.modalQuestionnaireFilter.end_date = moment(this.modalQuestionnaireFilter.end_date, 'YYYY-MM-DD').toDate();
            }

            if (this.modalQuestionnaireFilter.last_start_date) {
                this.modalQuestionnaireFilter.last_start_date = moment(this.modalQuestionnaireFilter.last_start_date, 'YYYY-MM-DD').toDate();
            }
        },
    },
}
</script>
