<template>
    <form-modal
        ref="formModal"
        :handle-hide="cleanModal"
        :v="$v.modalQuestionnaire"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        :size="tabIndex === 0 ? 'lg' : 'xl'"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nQuestionnaire"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nQuestionnaire"
                />
                <p v-if="modalQuestionnaire.hasAnswers" class="text-danger"
                    >* {{ getI18n(i18nKey, 'ERRORS.question_has_answers') }}</p
                >
                <b-row v-if="tabIndex === 0 && !isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'QUESTIONNAIRE')"
                                :title="i18nInformation"
                                :disabled="deleteLoading"
                                @onClick="isEdit = true"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'QUESTIONNAIRE')"
                                :title="getI18n(i18nKey, 'TITLES.questionnaire')"
                                :busy="deleteLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-tabs
                    id="questionnaire-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                    @activate-tab="tabActivated">
                    <b-tab :title="i18nInformation">
                        <b-row>
                            <b-col md="12">
                                <b-form-group
                                    :label="i18nName"
                                    class="required label-pdv"
                                    label-for="modal-name"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_questionnaire')"
                                >
                                    <b-form-input
                                        id="modal-name"
                                        v-model.trim="$v.modalQuestionnaire.name.$model"
                                        class="input-pdv-blue"
                                        name="name"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :state="validateField('name', 'modalQuestionnaire')"
                                        :placeholder="i18nName"
                                        :disabled="!isEdit || (isQuestionnaireCreated && modalQuestionnaire.hasAnswers)"
                                        @blur="$v.modalQuestionnaire.name.$touch"
                                        @input="clearResponseError('name', 'modalQuestionnaire')"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nType"
                                    class="required label-pdv"
                                    label-for="modal-type"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_type')"
                                >
                                    <multiselect
                                        v-model="typeSelected"
                                        :class="validationClass($v.modalQuestionnaire.type)"
                                        :options="questionnaireTypes"
                                        track-by="value"
                                        label="text"
                                        :searchable="false"
                                        :show-labels="false"
                                        :disabled="!isEdit || isQuestionnaireCreated"
                                        :placeholder="i18nType"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    label="Pergunta Justificavél"
                                    class="label-pdv"
                                    label-for="is-justifiable-answers"
                                >
                                    <b-form-checkbox
                                        id="is-justifiable-answers"
                                        v-model="modalQuestionnaire.is_justifiable"
                                        name="is-justifiable"
                                        :disabled="!isEdit || (isQuestionnaireCreated && modalQuestionnaire.hasAnswers)"
                                        switch
                                        size="lg"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab
                        id="questionnaire-itens-tab"
                        :disabled="isEdit || !isQuestionnaireCreated"
                    >
                        <template v-slot:title>
                            <b-spinner
                                v-if="loadingItems"
                                class="mr-1"
                                type="border"
                                small
                            />
                            {{ i18nQuestion }}
                        </template>
                        <questionnaireItemTab
                            ref="questionnaireItemTab"
                            v-model="modalQuestionnaire.questionnaireItems"
                            :questionnaire-id="modalQuestionnaire.id"
                            :disabled="isEdit"
                            :busy="loadingItems"
                        />
                    </b-tab>
                    <b-tab
                        id="questionnaire-filters-tab"
                        :disabled="isEdit || !isQuestionnaireCreated || modalQuestionnaire.type === 'new_pos'"
                    >
                        <template v-slot:title>
                            <b-spinner
                                v-if="loadingFilters"
                                class="mr-1"
                                type="border"
                                small
                            />
                            {{ i18nFilters }}
                        </template>
                        <questionnaireFilterTab
                            v-model="modalQuestionnaire.filters"
                            :questionnaire-id="modalQuestionnaire.id"
                            :questionnaire-type="modalQuestionnaire.type"
                            :disabled="isEdit"
                            :busy="loadingFilters"
                        />
                    </b-tab>
                    <b-tab
                        id="questionnaire-emails-tab"
                        :disabled="isEdit || !isQuestionnaireCreated"
                    >
                        <template v-slot:title>
                            <b-spinner
                                v-if="loadingEmails"
                                class="mr-1"
                                type="border"
                                small
                            />
                            {{ i18nEmails }}
                        </template>
                        <questionnaireEmailTab
                            v-model="modalQuestionnaire.emails"
                            :questionnaire-id="modalQuestionnaire.id"
                            :disabled="isEdit"
                            :busy="loadingEmails"
                        />
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="isQuestionnaireCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@components/FormModal'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import QuestionnairesService from '@src/services/QuestionnairesService.js'
import QuestionnaireItemsService from '@src/services/QuestionnaireItemsService.js'
import QuestionnaireFiltersService from '@src/services/QuestionnaireFiltersService.js'
import validationMixin from '@src/mixins/validation'
import swalFeedback from '@src/mixins/swalFeedback'
import questionnaireTypes from '@src/mixins/questionnaireTypes'
import questionnaireItemTab from '@src/router/views/questionnaires/questionnaireItemTab.vue'
import questionnaireFilterTab from '@src/router/views/questionnaires/questionnaireFilterTab.vue'
import questionnaireEmailTab from '@src/router/views/questionnaires/questionnaireEmailTab.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import QuestionnaireEmailsService from "@src/services/QuestionnaireEmailsService";

const i18nCommon = 'COMMON'
const i18nKey = 'QUESTIONNAIRES'

export default {
    components: {
        FormModal,
        Multiselect,
        questionnaireItemTab,
        questionnaireFilterTab,
        questionnaireEmailTab,
        ConfirmationFormModal,
        FeedbackModal,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        questionnaireTypes
    ],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        modal: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    name: '',
                    type: '',
                    questionnaireItems: [],
                    filters: [],
                    emails: [],
                    hasAnswers: null,
                    is_justifiable: false
                }
            },
        },
        callback: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalQuestionnaire: this.modal,
            isEdit: false,
            submitLoading: false,
            deleteLoading: false,
            loadingItems: false,
            loadingFilters: false,
            loadingEmails: false,
            itemsLoaded: false,
            filtersLoaded: false,
            emailsLoaded: false,
            tabIndex: 0,
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'question',
                    title: this.getI18n(i18nCommon, 'type'),
                    sortField: 'type',
                    formatter: this.typeToText,
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
        }
    },
    validations: {
        modalQuestionnaire: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },

            type: {
                required,
                inTypes(value) {
                    const acceptedTypes = this.questionnaireTypes.map(
                        (type) => type.value
                    )
                    return (
                        acceptedTypes.findIndex((type) => type === value) > -1
                    )
                },
            },
        },
    },

    computed: {
        isQuestionnaireCreated() {
            return this.modalQuestionnaire.id > 0
        },
        i18nTitleModal() {
            if (this.isQuestionnaireCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nQuestionnaire}`
                }
                return this.modalQuestionnaire.name
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_questionnaire')
            }
        },
        invalidListFeedback() {
            return this.getI18nModifiedWithNumber({
                prefix: 'ERROR_CODES',
                suffix: 'insufficient_items',
                number: 1,
            })
        },
        i18nQuestionnaire() {
            return this.getI18n(i18nKey, 'TITLES.questionnaire')
        },
        i18nType() {
            return this.getI18n(i18nCommon, 'type')
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'info',
                modifier: 2,
            })
        },
        i18nQuestion() {
            return this.getI18nModified({
                prefix: 'QUESTIONS',
                suffix: 'TITLES.question',
                modifier: 2,
            })
        },
        i18nFilters() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'filter',
                modifier: 2,
            })
        },
        i18nEmails() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'email',
                modifier: 2,
            })
        },
        modalOperation() {
            return this.isQuestionnaireCreated ? 'edited' : 'created'
        },
        typeSelected: {
            get: function () {
                const selected =
                    this.questionnaireTypes.find(
                        (types) => types.value === this.modalQuestionnaire.type
                    ) || null

                return selected
            },
            set: function (newValue) {
                if (typeof newValue === 'object') {
                    this.modalQuestionnaire.type = newValue.value
                } else {
                    this.modalQuestionnaire.type = newValue
                }
            },
        }
    },
    methods: {
        async tabActivated(newTabIndex) {
            if (newTabIndex === 1) {
                this.loadQuestionnaireItems()
            }
            else if (newTabIndex === 2) {
                this.loadQuestionnaireFilters()
            }
            else if (newTabIndex === 3) {
                this.loadQuestionnaireEmails()
            }
        },
        showModal() {
            this.typeSelected = this.modal.type
            this.isEdit = this.modalIsEdit
            this.submitLoading = false
            this.deleteLoading = false
            this.loadingItems = false
            this.loadingFilters = false
            this.loadingEmails = false
            this.itemsLoaded = false
            this.filtersLoaded = false
            this.emailsLoaded = false
            this.tabIndex = 0
            this.$nextTick(() => this.$refs.formModal.show())
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        cleanModal() {
            this.replaceModalData({
                id: 0,
                name: '',
                type: '',
                questionnaireItems: [],
                filters: [],
                emails: [],
                hasAnswers: null,
                is_justifiable: false,
            })
            this.tabIndex = 0
            this.resetValidation()
        },

        replaceModalData(data) {
            Object.assign(this.modalQuestionnaire, data)
        },

        async loadQuestionnaireItems() {
            if (this.itemsLoaded || this.loadingItems) {
                return
            }

            this.loadingItems = true
            await QuestionnaireItemsService.fetchAll({
                search: `questionnaire_id:${this.modalQuestionnaire.id}`,
                with: ['question', 'choices', 'fluxChoices', 'fluxBooleans'],
                check_for_answers: true,
                orderBy: 'position',
                per_page: 999,
            }).then((response) => {
                this.modalQuestionnaire.questionnaireItems = response.data.data
                this.itemsLoaded = true
            })
            this.loadingItems = false
        },

        async loadQuestionnaireFilters() {
            if (this.filtersLoaded || this.loadingFilters) {
                return
            }

            this.loadingFilters = true
            await QuestionnaireFiltersService.fetchAll({
                search: `questionnaire_id:${this.modalQuestionnaire.id}`,
                with: [
                    'roles',
                    'users',
                    'products',
                    'pointOfSales',
                    'businessUnit',
                    'recurrence',
                ],
                check_for_answers: true,
            }).then((response) => {
                this.modalQuestionnaire.filters = response.data.data
                this.filtersLoaded = true
            })
            this.loadingFilters = false
        },

        async loadQuestionnaireEmails() {
            if (this.emailsLoaded || this.loadingEmails) {
                return
            }

            this.loadingEmails = true
            await QuestionnaireEmailsService.fetchAll({
                search: `questionnaire_id:${this.modalQuestionnaire.id}`,
            }).then((response) => {
                this.modalQuestionnaire.emails = response.data.data
                this.emailsLoaded = true
            })
            this.loadingEmails = false
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        handleOk() {
            this.$v.modalQuestionnaire.$touch()
            if (!this.$v.modalQuestionnaire.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const operation = this.isQuestionnaireCreated ? 'edited' : 'created'
            const response = await this.createOrUpdateQuestionnaire()

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.successfulOperation(operation)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }

            this.submitLoading = false
        },
        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteQuestionnarie)
        },
        async deleteQuestionnarie(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.modalQuestionnaire.$touch()
            this.deleteLoading = true
            const response = await QuestionnairesService.delete(this.modalQuestionnaire.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nQuestionnaire, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
        createOrUpdateQuestionnaire() {
            const operation = this.isQuestionnaireCreated
                ? QuestionnairesService.update(
                      this.modalQuestionnaire.id,
                      this.modalQuestionnaire
                  )
                : QuestionnairesService.create(this.modalQuestionnaire)

            return operation
                .then((response) => {
                    this.replaceModalData(response.data.data)
                    return response
                })
                .catch((error) => {
                    this.unsuccessfulOperation()
                    return error
                })
        },
        successfulOperation(operation) {
            this.$emit('refresh')
            this.resetValidation()
            this.isEdit = false
            this.positiveFeedback(this.i18nQuestionnaire, operation)
        },
        unsuccessfulOperation(operation) {
            this.negativeFeedback()
        },
    },
}
</script>

<style>
#questionnaire-tabs .nav-link.disabled {
    background: #eaeaea;
}
</style>
