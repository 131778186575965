<template>
    <button
        type="button"
        class="btn btn-pdv btn-pdv-green"
        href="javascript: void(0);"
        :disabled="busy || disabled"
        @click="$emit('onClick')"
    >
        <template v-if="busy">
            <b-spinner small></b-spinner>
        </template>
        <template v-else>
            {{ `${getI18n('COMMON.clone')} ${title}` }}
        </template>
    </button>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
}
</script>
