<template>
    <b-form-group
        class="required"
        :invalid-feedback="getI18n('ERROR_CODES.invalid_item')"
    >
        <b-row v-if="!disabled" class="mb-2">
            <div class="col-sm-6 form-inline">
            </div>
            <div class="col-sm-6">
                <div class="text-sm-right">
                    <add-button
                        v-if="$can('CREATE', 'QUESTIONNAIREFILTER')"
                        :title="i18nNewQuestionnaireEmail"
                        :disabled="busy"
                        @onClick="createEmail"
                    />
                </div>
            </div>
        </b-row>
        <questionnaireEmailModal
            ref="questionnaireItemModal"
            :modal-is-edit="questionnaireEmailIsEdit"
            :modal="modalQuestionnaireEmail"
            @refresh="refreshTable"
        />
        <api-mode-vuetable
            ref="vuetable"
            :fetch-data="fetch"
            :fields="fields"
            :per-page="perPage"
            :additional-parameters="additionalParameters"
            :no-data-template="getI18n('ERROR_CODES', 'no_emails_created')">
            <template slot="actions" slot-scope="props">
                <div v-if="!disabled" class="table-button-container">
                    <more-table-button @onClick="editEmail(props.rowData)" />
                </div>
            </template>
        </api-mode-vuetable>
    </b-form-group>
</template>

<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import questionnaireEmailModal from '@src/router/views/questionnaires/questionnaireEmailModal'
import swalFeedback from '@src/mixins/swalFeedback'
import QuestionnaireEmailsService from '@src/services/QuestionnaireEmailsService'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import Pagination from '@src/mixins/pagination.vue'
import AddButton from '@/src/components/AddLabelButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'

const i18nCommon = 'COMMON'
const i18nKey = 'QUESTIONNAIRE_EMAILS'

export default {
    components: {
        ApiModeVuetable,
        questionnaireEmailModal,
        AddButton,
        MoreTableButton,
    },
    mixins: [swalFeedback, Pagination, vuetableFormatters],
    props: {
        questionnaireId: {
            type: Number,
            required: true,
        },
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            perPage: 10,
            css: {},
            fields: [
                {
                    name: 'subject',
                    title: this.getI18n(i18nKey, 'FIELDS.subject'),
                    sortField: 'subject',
                },
                {
                    name: 'recipient',
                    title: this.getI18n(i18nKey, 'FIELDS.recipient'),
                    sortField: 'recipient',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            additionalParameters: {
                search: `questionnaire_id:${this.questionnaireId}`
            },
            modalQuestionnaireEmail: {
                id: 0,
                questionnaire_id: this.questionnaireId,
                subject: '',
                recipient: '',
                conditions: [],
            },
            loadingeditEmail: [],
            questionnaireEmailIsEdit: false,
        }
    },
    computed: {
        questionnaireEmails: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        i18nQuestionnaireEmail() {
            return this.getI18n(i18nKey, 'TITLES.questionnaire_email')
        },
        i18nNewQuestionnaireEmail() {
            return this.getI18n(i18nKey, 'BUTTONS.new_questionnaire_email')
        },
    },
    watch: {
        questionnaireId(newValue) {
            this.additionalParameters = {
                search: `questionnaire_id:${newValue}`,
            }

            this.modalQuestionnaireEmail.questionnaire_id = newValue
        },
    },
    methods: {
        fetch(url, params) {
            return QuestionnaireEmailsService.fetchVuetable(url, params)
        },

        refreshTable() {
            this.$nextTick(() => this.$refs.vuetable.refresh())
        },

        createEmail() {
            this.questionnaireEmailIsEdit = false
            this.cleanModal()
            this.$refs.questionnaireItemModal.showModal(true)
        },
        editEmail(data) {
            this.questionnaireEmailIsEdit = true
            this.replaceModalData(data)
            this.$refs.questionnaireItemModal.showModal(false)
        },
        cleanModal() {
            Object.assign(this.modalQuestionnaireEmail, {
                id: 0,
                questionnaire_id: this.questionnaireId,
                subject: '',
                recipient: '',
                conditions: [],
            })
        },
        replaceModalData(data) {
            Object.assign(this.modalQuestionnaireEmail, data)
        },
    },
}
</script>
