<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import QuestionnairesService from '@src/services/QuestionnairesService.js'
import questionnairesModal from '@src/router/views/questionnaires/questionnairesModal'
import questionnaireGridFilterModal from '@src/router/views/questionnaires/questionnaireGridFilterModal'
import Layout from '@layouts/main'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@components/PageHeader'
import pagination from '@src/mixins/pagination'
import swalFeedback from '@src/mixins/swalFeedback'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import filterVuetable from '@src/mixins/filterVuetable'
import questionnaireTypes from '@src/mixins/questionnaireTypes'
import FilterBar from '@src/components/FilterBar'
import AddButton from '@/src/components/AddLabelButton.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import { debounce } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'QUESTIONNAIRES'

export default {
    components: {
        ApiModeVuetable,
        Layout,
        Breadcrumb,
        PageHeader,
        questionnairesModal,
        questionnaireGridFilterModal,
        FilterBar,
        AddButton,
        FilterLabelButton,
        MoreTableButton,
    },
    mixins: [
        swalFeedback,
        pagination,
        vuetableFormatters,
        filterVuetable,
        questionnaireTypes
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            modalIsEdit: false,
            modalQuestionnaire: {
                id: 0,
                name: '',
                type: '',
                questionnaireItems: [],
                filters: [],
                hasAnswers: null,
            },
            data: [],
            perPage: 10,
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'type',
                    title: this.getI18n(i18nCommon, 'type'),
                    sortField: 'type',
                    formatter: this.typeToText,
                },
                {
                    name: 'updated_at',
                    title: this.getI18n(i18nCommon, 'updated_at'),
                    sortField: 'updated_at',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            css: {},
            filterText: '',
            additionalParameters: {},
            filters: {
                type: '',
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'WORKSCHEDULES',
                        suffix: 'TITLES.work_schedule',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.questionnaire',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nType() {
            return this.getI18n(i18nCommon, 'type')
        },
        i18nNewQuestionnaire() {
            return this.getI18n(i18nKey, 'BUTTONS.new_questionnaire')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters = {
                search: '',
            }

            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText}`
            }

            this.refreshTable()
        }, 1000),
    },
    methods: {
        createAdditionalParameters() {
            const search = this.createSearch({
                name: this.filters.name,
                type: this.filters.type ? this.filters.type.value : null,
            })

            return this.formatParameters({
                search,
                searchJoin: search.search(';') > 0 ? 'and' : '',
            })
        },

        fetch(url, params) {
            return QuestionnairesService.fetchVuetable(url, params)
        },

        i18nQuestionnaire(modifier) {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.questionnaire',
                modifier: modifier,
            })
        },

        cleanModal() {
            this.replaceModalData({
                id: 0,
                name: '',
                type: '',
                questionnaireItems: [],
                filters: [],
                hasAnswers: null,
                is_justifiable: false,
            })
        },

        showModal() {
            this.$nextTick(() => this.$refs.questionsModal.showModal())
        },

        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },

        async openModalToEdit(questionnaire) {
            this.modalIsEdit = false
            this.replaceModalData(questionnaire)
            this.showModal()
        },

        replaceModalData(data) {
            Object.assign(this.modalQuestionnaire, data)

            this.modalQuestionnaire.questionnaireItems = []
            this.modalQuestionnaire.filters = []
        },

        filter(appliedFilters) {
            this.filters = appliedFilters
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },
        showFilterModal() {
            this.$nextTick(() =>
                this.$refs.questionnaireGridFilterModal.showModal(this.filters)
            )
        },

        reloadTable() {
            this.$refs.vuetable.reload()
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText" />
                    <filter-label-button @onClick="showFilterModal" />
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-button
                            v-if="$can('CREATE', 'QUESTIONNAIRE')"
                            :title="i18nNewQuestionnaire"
                            @onClick="openModalToCreate"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nQuestionnaire(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openModalToEdit(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <questionnaires-modal
            ref="questionsModal"
            :modal="modalQuestionnaire"
            :modal-is-edit="modalIsEdit"
            @refresh="reloadTable"
        />
        <questionnaire-grid-filter-modal
            ref="questionnaireGridFilterModal"
            :handle-submit="filter"
        />
    </Layout>
</template>
