<template>
    <form-modal
        ref="formModal"
        :handle-open="resetValidation"
        :v="$v.modalQuestionnaireFilter"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <questionnaire-filter-clone-modal
                    ref="questionnaireFilterCloneModal"
                    @afterClone="handleAfterClone"
                />
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nQuestionnaireFilter"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nQuestionnaireFilter"
                />
                <selectCsvModal
                    ref="productCsvModal"
                    :title="i18nSelectThroughCsv"
                    :service="'products'"
                    csv-type="products_reference"
                    :type-fields="selectFields.products_reference"
                    @csvCreated="loadProductsByJson"
                />
                <selectCsvModal
                    ref="posCsvModal"
                    :title="i18nSelectThroughCsv"
                    :service="'pos'"
                    csv-type="point_of_sales_external_code"
                    :type-fields="selectFields.point_of_sales_external_code"
                    @csvCreated="loadPosByJson"
                />
                <selectCsvModal
                    ref="roleCsvModal"
                    :title="i18nSelectThroughCsv"
                    :service="'roles'"
                    @csvCreated="loadRolesByJson"
                />
                <selectCsvModal
                    ref="userCsvModal"
                    :title="i18nSelectThroughCsv"
                    :service="'users'"
                    csv-type="users_email"
                    :type-fields="selectFields.users_email"
                    @csvCreated="loadUsersByJson"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <clone-label-button
                                v-if="$can('CREATE', 'QUESTIONNAIREFILTER')"
                                :title="getI18n('COMMON', 'filter')"
                                :disabled="editLoading || deleteLoading"
                                @onClick="handleClone"
                            />
                            <edit-label-button
                                v-if="$can('UPDATE', 'QUESTIONNAIREFILTER')"
                                :title="getI18n('COMMON', 'filter')"
                                :busy="editLoading"
                                :disabled="deleteLoading"
                                @onClick="handleEdit"
                            />
                            <delete-label-button
                                v-if="$can('DELETE', 'QUESTIONNAIREFILTER')"
                                :title="getI18n('COMMON', 'filter')"
                                :busy="deleteLoading"
                                :disabled="editLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-tabs
                    id="questionnaire-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                    @activate-tab="tabActivated">
                    <b-tab :title="i18nInformation">
                        <b-form-row>
                            <b-col md="6">
                                <b-form-group
                                    :label="i18nBusinessUnit"
                                    class="required label-pdv"
                                    label-for="modal-business-unit"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_business_unit')"
                                >
                                    <multiSelectWithService
                                        :id="'modal-business-unit'"
                                        ref="businessUnitMultiselect"
                                        v-model="modalQuestionnaireFilter.business_unit"
                                        :input-function="$v.modalQuestionnaireFilter.business_unit_id.$touch"
                                        :select-class="validationClass($v.modalQuestionnaireFilter.business_unit_id)"
                                        :service="'business-units'"
                                        label="name"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="!isEdit || isQuestionnaireFilterCreated"
                                        :placeholder="i18nBusinessUnit"
                                        :parameters="businessUnitsParameters"
                                        @input="changeBusinessUnitId"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="getI18n('COMMON', 'start_date')"
                                    class="required label-pdv"
                                    label-for="modal-start-date"
                                    :invalid-feedback="getI18n('ERROR_CODES', 'invalid_date')"
                                >
                                    <date-picker
                                        id="modal-start-date"
                                        :child-class="validationClass($v.modalQuestionnaireFilter.start_date)"
                                        :value.sync="$v.modalQuestionnaireFilter.start_date.$model"
                                        :min-date="modalQuestionnaireFilter.last_start_date ? modalQuestionnaireFilter.last_start_date : new Date()"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="getI18n('COMMON', 'end_date')"
                                    class="label-pdv"
                                    label-for="modal-end-date"
                                    :invalid-feedback="getI18n('ERROR_CODES', 'invalid_date')"
                                >
                                    <date-picker
                                        id="modal-end-date"
                                        :child-class="validationClass($v.modalQuestionnaireFilter.end_date)"
                                        :value.sync="$v.modalQuestionnaireFilter.end_date.$model"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col md="2">
                                <b-form-group
                                    :label="i18nRepeatable"
                                    label-for="modal-is-repeatable"
                                    class="label-pdv"
                                >
                                    <b-form-checkbox
                                        id="modal-is-repeatable"
                                        v-model="modalQuestionnaireFilter.is_repeatable"
                                        name="is_repeatable"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <b-form-group
                                    :label="i18nMultiple"
                                    label-for="modal-is-multiple"
                                    class="label-pdv"
                                >
                                    <b-form-checkbox
                                        id="modal-is-multiple"
                                        v-model="modalQuestionnaireFilter.is_multiple"
                                        name="is_multiple"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <b-form-group
                                    :label="i18nRequired"
                                    label-for="modal-is-required"
                                    class="label-pdv"
                                >
                                    <b-form-checkbox
                                        id="modal-is-required"
                                        v-model="modalQuestionnaireFilter.is_required"
                                        name="is_required"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit"
                                    />
                                </b-form-group>
                            </b-col>
                            <!-- <b-col md="2">
                                <b-form-group
                                    :label="i18nUninterrupted"
                                    label-for="modal-is-required"
                                    class="label-pdv"
                                >
                                    <b-form-checkbox
                                        id="modal-is-ninterrupted"
                                        v-model="modalQuestionnaireFilter.is_uninterrupted"
                                        name="is_uninterrupted"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit"
                                    />
                                </b-form-group>
                            </b-col> -->
                        </b-form-row>
                        <b-form-row v-if="modalQuestionnaireFilter.is_repeatable">
                            <b-col md="12">
                                <b-form-group
                                    :label="getI18n('COMMON', 'frequency')"
                                    class="required label-pdv"
                                    label-for="modal-business-unit"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_frequency')"
                                >
                                    <treeselect
                                        v-model="modalQuestionnaireFilter.human_readable_frequencies"
                                        :class="validationClass($v.modalQuestionnaireFilter.human_readable_frequencies)"
                                        :multiple="true"
                                        :options="frequencies"
                                        :value-consists-of="valueConsistsOf"
                                        disabled
                                        @input="$v.modalQuestionnaireFilter.human_readable_frequencies.$touch;"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row v-else>
                            <questionnaire-recurrence-items
                                ref="questionnaireRecurrenceItems"
                                :value="modalQuestionnaireFilter.recurrenceItems"
                                :busy="submitLoading"
                                :disabled="!isEdit"
                            />
                        </b-form-row>
                    </b-tab>
                    <b-tab :title="i18nUsers">
                        <b-row v-if="isEdit" class="d-flex justify-content-end">
                            <import-button
                                @onClick="showUserCsvModal"
                            />
                        </b-row>
                        <b-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nRoles"
                                    label-for="add-role-input"
                                    class="label-pdv" >
                                    <multiSelectWithService
                                        :id="'add-role-input'"
                                        ref="roleMultiselect"
                                        v-model="roleName"
                                        :service="'roles'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="submitLoading"
                                        :parameters="rolesParameters"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="modalQuestionnaireFilter.roles"
                                    :fields="roleChildrenActionFields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    small
                                    @onClickDelete="handleRemoveRole"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nUsers"
                                    label-for="add-user-input"
                                    class="label-pdv" >
                                    <multiSelectWithService
                                        :id="'add-user-input'"
                                        ref="userMultiselect"
                                        v-model="userName"
                                        :service="'users'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="submitLoading"
                                        :parameters="usersParameters"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="modalQuestionnaireFilter.users"
                                    :fields="userChildrenActionFields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    small
                                    @onClickDelete="handleRemoveUser"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nPos">
                        <b-row v-if="isEdit" class="d-flex justify-content-end">
                            <import-button
                                @onClick="showPosCsvModal"
                            />
                        </b-row>
                        <b-row>
                            <b-col class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nPos"
                                    label-for="add-pos-input"
                                    class="label-pdv" >
                                    <multiSelectWithService
                                        :id="'add-pos-input'"
                                        ref="posMultiselect"
                                        v-model="posName"
                                        :service="'pos'"
                                        :parameters="posParameters"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="!isEdit || modalQuestionnaireFilter.business_unit_id <= 0 || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-sm-12 col-md-8">
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="modalQuestionnaireFilter.point_of_sales"
                                    :fields="posChildrenActionFields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    @onClickDelete="handleRemovePos"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab
                        v-if="modalQuestionnaireFilter.questionnaire_type === 'product'"
                        :title="i18nProducts"
                    >
                        <b-row v-if="isEdit" class="d-flex justify-content-end">
                            <import-button
                                @onClick="showProductCsvModal"
                            />
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                    :label="i18nProductType"
                                    class="required label-pdv"
                                    label-for="modal-is-competitor"
                                >
                                    <b-form-select
                                        id="modal-is-competitor"
                                        v-model="isCompetitor"
                                        class="input-pdv-blue"
                                        :options="competitorProductsOptions"
                                        :disabled="!isEdit"
                                        @change="productTypeChanged"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nProducts"
                                    label-for="add-product-input"
                                    class="label-pdv" >
                                    <multiSelectWithService
                                        :id="'add-product-input'"
                                        ref="productMultiselect"
                                        v-model="productName"
                                        :service="'products'"
                                        :parameters="productsParameters"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col class="col-sm-12 col-md-8">
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="modalQuestionnaireFilter.products"
                                    :fields="productChildrenActionFields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    @onClickDelete="handleRemoveProduct"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab
                        v-if="modalQuestionnaireFilter.questionnaire_type === 'product_category'"
                        :title="i18nCategories"
                    >
                        <ProductCategoryRelation
                            ref="productCategoriesRestrictionVuetable"
                            v-model="modalQuestionnaireFilter.product_categories"
                            :busy="submitLoading"
                            :disabled="!isEdit"
                        />
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="modalQuestionnaireFilter.questionnaire_type === ('product' || 'product_category') && tabIndex !== 3"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :busy="submitLoading"
                    @onClick="tabIndex++"
                />
                <save-button
                    v-else-if="modalQuestionnaireFilter.questionnaire_type === ('generic' || 'out_of_stock') && tabIndex !== 2"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :busy="submitLoading"
                    @onClick="tabIndex++"
                />
                <save-button
                    v-else-if="isQuestionnaireFilterCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import { Treeselect } from '@riophae/vue-treeselect'
import {
    required,
    numeric,
    minLength,
    minValue,
    requiredIf,
} from 'vuelidate/lib/validators'

import swalFeedback from '@src/mixins/swalFeedback'

import QuestionnaireFiltersService from '@src/services/QuestionnaireFiltersService.js'

import validationMixin from '@src/mixins/validation'

import frequencies from '@src/mixins/frequencies'

import multiSelectWithService from '@src/components/multiSelectWithService'
import FormModal from '@components/FormModal'
import SelectCsvModal from '@src/components/SelectCsvModal'
import csvFields from '@src/mixins/csvFields'
import filterVuetable from '@src/mixins/filterVuetable'
import QuestionnaireRecurrenceItems from '@views/questionnaires/questionnaireRecurrenceItems'
import QuestionnaireRecurrenceItemsService from '@src/services/QuestionnaireRecurrenceItemsService'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import questionnaireFilterCloneModal from '@src/router/views/questionnaires/questionnaireFilterCloneModal.vue'
import ProductCategoryRelation from '@components/ProductCategoryRelation'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import ImportButton from '@/src/components/ImportButton.vue'
import SaveButton from '@/src/components/SaveButton'
import CloneLabelButton from '@/src/components/CloneLabelButton.vue'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import DatePicker from '@/src/components/DatePicker'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import userChildrenActionFields from '@src/fields/childrens/userChildrenActionFields'
import roleChildrenActionFields from '@src/fields/childrens/roleChildrenActionFields'
import productChildrenActionFields from '@src/fields/childrens/productChildrenActionFields'
import posChildrenActionFields from '@src/fields/childrens/posChildrenActionFields'
import moment from 'moment';
import { unionBy } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'QUESTIONNAIRE_FILTERS'

export default {
    components: {
        ProductCategoryRelation,
        QuestionnaireRecurrenceItems,
        questionnaireFilterCloneModal,
        ConfirmationFormModal,
        FeedbackModal,
        FormModal,
        SelectCsvModal,
        multiSelectWithService,
        Treeselect,
        CancelButton,
        AddButton,
        ImportButton,
        SaveButton,
        CloneLabelButton,
        EditLabelButton,
        DeleteLabelButton,
        DatePicker,
        DataModeVuetable,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        frequencies,
        csvFields,
        filterVuetable,
        userChildrenActionFields,
        roleChildrenActionFields,
        productChildrenActionFields,
        posChildrenActionFields
    ],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        modal: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    questionnaire_id: 0,
                    business_unit_id: 0,
                    business_unit: {
                        id: 0,
                        name: '',
                    },
                    has_role_restrictions: false,
                    has_user_restrictions: false,
                    has_product_restrictions: false,
                    has_point_of_sale_restrictions: false,
                    has_product_categories_restrictions: false,
                    is_repeatable: false,
                    is_multiple: false,
                    is_required: false,
                    is_uninterrupted: false,
                    competitor_products: null,
                    human_readable_frequencies: ['ANY_DAY'],
                    start_date: null,
                    end_date: null,
                    last_start_date: null,
                    point_of_sales: [],
                    roles: [],
                    users: [],
                    products: [],
                    product_categories: [],
                    recurrenceItems: [],
                }
            },
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalQuestionnaireFilter: this.modal,
            isEdit: false,
            editLoading: false,
            submitLoading: false,
            deleteLoading: false,
            tabIndex: 0,
            roleName: '',
            userName: '',
            productName: '',
            posName: '',
            valueConsistsOf: 'BRANCH_PRIORITY',
            isCompetitor: null,
            businessUnitsParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            productsParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            posParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            rolesParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
            competitorProductsOptions: [
                {
                    value: null,
                    text: this.getI18n(i18nKey, 'TITLES.both'),
                },
                {
                    value: true,
                    text: this.getI18n(i18nKey, 'TITLES.only_competitor'),
                },
                {
                    value: false,
                    text: this.getI18n(i18nKey, 'TITLES.only_own'),
                },
            ],
        }
    },
    validations: {
        modalQuestionnaireFilter: {
            business_unit_id: {
                required,
                numeric,
                minValue: minValue(1),
            },
            start_date: {
                required,
                minDate(value, model) {
                    if (this.isQuestionnaireFilterCreated && model.last_start_date && moment(value).isSame(model.last_start_date)) {
                        return true
                    }
                    return value >= moment().startOf('day').toDate()
                },
                beforeEndDate(value, model) {
                    return !model.end_date || value <= model.end_date
                },
            },
            end_date: {
                afterStartDate(value, model) {
                    return !value || value >= model.start_date
                },
            },

            human_readable_frequencies: {
                required,
                minLength: minLength(1),
            },

            recurrenceItems: {
                required: requiredIf(function (modal) {
                    return !modal.is_repeatable
                }),
                minLength: minLength(1),
            },
        },
    },

    computed: {
        isQuestionnaireFilterCreated() {
            return this.modalQuestionnaireFilter.id > 0
        },
        i18nTitleModal() {
            if (this.isQuestionnaireFilterCreated) {
                if (this.isEdit) {
                    return this.getI18n(i18nKey, 'TITLES.edit_questionnaire_filter')
                }
                return this.i18nQuestionnaireFilter
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_questionnaire_filter')
            }
        },
        i18nQuestionnaireFilter() {
            return this.getI18n(i18nKey, 'TITLES.questionnaire_filter')
        },
        i18nBusinessUnit() {
            return this.getI18n('BUSINESS_UNITS', 'TITLES.business_unit')
        },
        modalOperation() {
            return this.isQuestionnaireFilterCreated ? 'edited' : 'created'
        },
        i18nRepeatable() {
            return this.getI18n(i18nKey, 'FIELDS.is_repeatable')
        },
        i18nMultiple() {
            return this.getI18n(i18nKey, 'FIELDS.is_multiple')
        },
        i18nRequired() {
            return this.getI18n(i18nKey, 'FIELDS.is_required')
        },
        i18nUninterrupted() {
            return this.getI18n(i18nKey, 'FIELDS.is_uninterrupted')
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'info',
                modifier: 2,
            })
        },
        i18nRoles() {
            return this.getI18nModified({
                        prefix: 'ROLES',
                        suffix: 'TITLES.role',
                        modifier: 2,
                    })
        },
        i18nUsers() {
            return this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.user',
                        modifier: 2,
                    })
        },
        i18nPos() {
            return this.getI18nModified({
                        prefix: 'POS',
                        suffix: 'TITLES.pos',
                        modifier: 2,
                    })
        },
        i18nProducts() {
            return this.getI18nModified({
                        prefix: 'PRODUCTS',
                        suffix: 'TITLES.product',
                        modifier: 2,
                    })
        },
        i18nProductType() {
            return this.getI18n('PRODUCTS', 'TITLES.product_type')
        },
        i18nCategories() {
            return this.getI18nModified({
                        prefix: 'PRODUCT_CATEGORIES',
                        suffix: 'TITLES.product_category',
                        modifier: 2,
                    })
        },
        i18nSuccessfullyProcessed() {
            return this.getI18nModified({
                prefix: 'RESPONSES',
                suffix: 'successfully_processed',
                modifier: 3,
            })
        },
        i18nSelectThroughCsv() {
            return this.getI18n('QUESTIONNAIRE_FILTERS', 'TITLES.select_through_csv')
        },
        i18nNoRecordsFound() {
            return this.getI18n('PAGINATION', 'no_records_found')
        },
    },

    watch: {
        productName(value) {
            if (value) {
                const index = this.modalQuestionnaireFilter.products.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.modalQuestionnaireFilter.products.push(value)
                }
                this.productName = ''
                this.$refs.productMultiselect.$el.focus()
            }
        },
        roleName(value) {
            if (value) {
                const index = this.modalQuestionnaireFilter.roles.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.modalQuestionnaireFilter.roles.push(value)
                }
                this.roleName = ''
                this.$refs.roleMultiselect.$el.focus()
            }
        },
        userName(value) {
            if (value) {
                const index = this.modalQuestionnaireFilter.users.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.modalQuestionnaireFilter.users.push(value)
                }
                this.userName = ''
                this.$refs.userMultiselect.$el.focus()
            }
        },
        posName(value) {
            if (value) {
                const index = this.modalQuestionnaireFilter.point_of_sales.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.modalQuestionnaireFilter.point_of_sales.push(value)
                }
                this.posName = ''
                this.$refs.posMultiselect.$el.focus()
            }
        },
    },

    methods: {
        tabActivated(newTabIndex, oldTabIndex, event) {
            if (this.isEdit) {
                if (oldTabIndex === 0) {
                    const checkRecurrenceItems = !this.modalQuestionnaireFilter.is_repeatable && this.modalQuestionnaireFilter.recurrenceItems.length === 0

                    this.$v.modalQuestionnaireFilter.$touch()
                    if (checkRecurrenceItems) {
                        this.$refs.questionnaireRecurrenceItems.$v.recurrenceItem.$touch()
                    }

                    if (this.$v.modalQuestionnaireFilter.$invalid || (checkRecurrenceItems && this.$refs.questionnaireRecurrenceItems.$v.recurrenceItem.$invalid)) {
                        event.preventDefault();
                    }
                }
            }
        },

        changeBusinessUnitId(newValue) {
            const oldValue = this.modalQuestionnaireFilter.business_unit_id
            this.modalQuestionnaireFilter.business_unit_id = newValue ? newValue.id : null
            this.clearResponseError('business_unit_id', 'modalQuestionnaireFilter')

            if (this.modalQuestionnaireFilter.business_unit_id > 0) {
                if (this.modalQuestionnaireFilter.business_unit_id !== oldValue) {
                    this.modalQuestionnaireFilter.point_of_sales = []
                }

                this.posParameters = this.formatParameters({
                    search: '',
                    per_page: 1000,
                    page: 1,
                    orderBy: 'name',
                    sortedBy: 'asc',
                    business_unit_id: [this.modalQuestionnaireFilter.business_unit_id],
                })

                this.$nextTick(() => {
                    this.$refs.posMultiselect.loadOptions(this.posParameters)
                })
            }
        },

        productTypeChanged(newValue) {
            if (this.modalQuestionnaireFilter.products.length > 0) {
                if (newValue === true) {
                    const products = this.modalQuestionnaireFilter.products.filter(product => product.is_competitor === false)
                    if (products.length > 0) {
                        this.isCompetitor = null
                        this.warningFeedback(this.i18nQuestionnaireFilter, this.getI18n(this.i18nKey, 'MESSAGES.remove_all_products_themselves'))
                        return
                    }
                }
                else if (newValue === false) {
                    const products = this.modalQuestionnaireFilter.products.filter(product => product.is_competitor === true)
                    if (products.length > 0) {
                        this.isCompetitor = null
                        this.warningFeedback(this.i18nQuestionnaireFilter, this.getI18n(this.i18nKey, 'MESSAGES.remove_all_products_is_competition'))
                        return
                    }
                }
            }

            this.productsParameters = this.formatParameters({
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                is_competitor: newValue,
                active: true,
            })

            if (this.modalQuestionnaireFilter.questionnaire_type === 'product') {
                this.$nextTick(() => {
                    this.$refs.productMultiselect.loadOptions(this.productsParameters)
                })
            }

            this.modalQuestionnaireFilter.competitor_products = newValue
        },

        showModal(isEdit) {
            this.businessUnitsParameters.questionnaire_id = this.modalQuestionnaireFilter.questionnaire_id
            this.isEdit = isEdit
            this.editLoading = false
            this.submitLoading = false
            this.deleteLoading = false
            this.tabIndex = 0
            this.roleName = ''
            this.userName = ''
            this.productName = ''
            this.posName = ''
            this.isCompetitor = this.modalQuestionnaireFilter.competitor_products
            this.$nextTick(() => this.$refs.formModal.show())
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        cleanModal() {
            this.replaceModalData({
                business_unit_id: 0,
                business_unit: {
                    id: 0,
                    name: '',
                },
                has_role_restrictions: false,
                has_user_restrictions: false,
                has_product_restrictions: false,
                has_point_of_sale_restrictions: false,
                has_product_categories_restrictions: false,
                competitor_products: null,
                human_readable_frequencies: ['ANY_DAY'],
                start_date: null,
                end_date: null,
                last_start_date: null,
                point_of_sales: [],
                roles: [],
                users: [],
                products: [],
                product_categories: [],
            })
            this.resetValidation()
        },

        replaceModalData(data) {
            Object.assign(this.modalQuestionnaireFilter, data)
        },

        handleClone() {
            const data = {
                questionnaire_filter_id: this.modalQuestionnaireFilter.id,
                questionnaire_id: this.modalQuestionnaireFilter.questionnaire_id,
                current_business_unit_id: this.modalQuestionnaireFilter.business_unit_id,
            }
            this.$refs.questionnaireFilterCloneModal.showModal(data)
        },

        handleEdit() {
            this.editLoading = true

            this.isEdit = true
            this.changeBusinessUnitId(this.modalQuestionnaireFilter.business_unit)
            this.productTypeChanged(this.modalQuestionnaireFilter.competitor_products)

            this.editLoading = false
        },

        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },

        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },

        changeRestrictionBooleans() {
            this.modalQuestionnaireFilter.has_role_restrictions = this.modalQuestionnaireFilter.roles.length > 0
            this.modalQuestionnaireFilter.has_user_restrictions = this.modalQuestionnaireFilter.users.length > 0
            this.modalQuestionnaireFilter.has_product_restrictions = this.modalQuestionnaireFilter.products.length > 0
            this.modalQuestionnaireFilter.has_point_of_sale_restrictions = this.modalQuestionnaireFilter.point_of_sales.length > 0
            this.modalQuestionnaireFilter.has_product_categories_restrictions = this.modalQuestionnaireFilter.product_categories.length > 0
        },

        handleOk() {
            this.$v.modalQuestionnaireFilter.$touch()
            if (!this.$v.modalQuestionnaireFilter.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            await this.changeRestrictionBooleans()

            const localModal = {}
            Object.assign(localModal, this.modalQuestionnaireFilter)

            if (!localModal.is_repeatable) {
                localModal.human_readable_frequencies = ['ANY_DAY']
            }

            const modalInfo = {
                ...localModal,
                roles: this.modalQuestionnaireFilter.roles.map((el) => el.id),
                users: this.modalQuestionnaireFilter.users.map((el) => el.id),
                products: this.modalQuestionnaireFilter.products.map(
                    (el) => el.id
                ),
                point_of_sales: this.modalQuestionnaireFilter.point_of_sales.map(
                    (el) => el.id
                ),
                product_categories: this.modalQuestionnaireFilter.product_categories.map(
                    (el) => el.id ? el.id : el
                ),
                start_date: moment(this.modalQuestionnaireFilter.start_date).format('YYYY-MM-DD'),
                end_date: this.modalQuestionnaireFilter.end_date ? moment(this.modalQuestionnaireFilter.end_date).format('YYYY-MM-DD') : null,
            }

            const operation = this.isQuestionnaireFilterCreated
                ? QuestionnaireFiltersService.update(modalInfo.id, modalInfo)
                : QuestionnaireFiltersService.create(modalInfo)

            const createResponse = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = createResponse.status.toString()

            if (statusCode.charAt(0) === '2') {
                if (!createResponse.data.data.is_repeatable) {
                    this.createRecurrenceFilters(createResponse.data.data)
                } else {
                    this.successfulOperation()
                }
            }

            if (statusCode.charAt(0) === '4') {
                this.unsuccessfulOperation()
            }

            if (statusCode === '422') {
                this.modalResponseErrors = createResponse.data.errors
                this.warningFeedbackApi(this.i18nQuestionnaireFilter, createResponse.data.errors)
            }

            this.submitLoading = false
        },

        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteQuestionnarieFilter)
        },

        async deleteQuestionnarieFilter(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.modalQuestionnaireFilter.$touch()
            this.deleteLoading = true
            const response = await QuestionnaireFiltersService.delete(this.modalQuestionnaireFilter.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nQuestionnaireFilter, 'deleted', 3)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },

        async createRecurrenceFilters(questionnaireFilter) {
            if (!questionnaireFilter.is_repeatable) {
                const recurrenceFilter = {
                    questionnaire_filter_id: this.modalIsEdit
                        ? questionnaireFilter.id
                        : questionnaireFilter.id,
                    recurrenceItems: this.modalQuestionnaireFilter
                        .recurrenceItems,
                }

                const recurrenceOperation = QuestionnaireRecurrenceItemsService.create(
                    recurrenceFilter
                )

                const createResponse = await recurrenceOperation
                    .then((response) => response)
                    .catch((error) => {
                        this.unsuccessfulOperation()
                        return error
                    })

                const statusCode = createResponse.status.toString()

                if (statusCode.charAt(0) === '2') {
                    this.successfulOperation()
                }
            }
        },

        successfulOperation() {
            this.$emit('refresh')
            this.resetValidation()
            this.$refs.formModal.hide()
            this.positiveFeedback(this.i18nQuestionnaireFilter, this.modalOperation)
        },

        unsuccessfulOperation() {
            this.negativeFeedback()
        },

        handleRemoveRole(id) {
            const index = this.modalQuestionnaireFilter.roles.findIndex(find => find.id === id);

            if (index > -1) {
                this.modalQuestionnaireFilter.roles.splice(index, 1);
            }
        },

        handleRemoveUser(id) {
            const index = this.modalQuestionnaireFilter.users.findIndex(find => find.id === id);

            if (index > -1) {
                this.modalQuestionnaireFilter.users.splice(index, 1);
            }
        },

        handleRemoveProduct(id) {
            const index = this.modalQuestionnaireFilter.products.findIndex(find => find.id === id);

            if (index > -1) {
                this.modalQuestionnaireFilter.products.splice(index, 1);
            }
        },

        handleRemovePos(id) {
            const index = this.modalQuestionnaireFilter.point_of_sales.findIndex(find => find.id === id);

            if (index > -1) {
                this.modalQuestionnaireFilter.point_of_sales.splice(index, 1);
            }
        },

        showProductCsvModal() {
            this.$nextTick(() => this.$refs.productCsvModal.showModal())
        },
        showPosCsvModal() {
            this.$nextTick(() => this.$refs.posCsvModal.showModal())
        },
        showUserCsvModal() {
            this.$nextTick(() => this.$refs.userCsvModal.showModal())
        },
        async loadPosByJson(csv) {
            const data = await this.$refs.posCsvModal.fetchRelationItems(csv)

            if (data && data.length > 0) {
                this.modalQuestionnaireFilter.point_of_sales = unionBy(this.modalQuestionnaireFilter.point_of_sales, data, 'id')
                this.successFeedback(this.i18nSelectThroughCsv, this.i18nSuccessfullyProcessed)
            } else {
                this.warningFeedback(this.i18nSelectThroughCsv, this.i18nNoRecordsFound)
            }

            this.$refs.posCsvModal.hideModal()
        },
        async loadProductsByJson(csv) {
            const data = await this.$refs.productCsvModal.fetchRelationItems(csv, {
                is_competitor: this.modalQuestionnaireFilter.competitor_products,
            })

            if (data && data.length > 0) {
                this.modalQuestionnaireFilter.products = unionBy(this.modalQuestionnaireFilter.products, data, 'id')
                this.successFeedback(this.i18nSelectThroughCsv, this.i18nSuccessfullyProcessed)
            } else {
                this.warningFeedback(this.i18nSelectThroughCsv, this.i18nNoRecordsFound)
            }

            this.$refs.productCsvModal.hideModal()
        },
        async loadRolesByJson(csv) {
            const data = await this.$refs.roleCsvModal.fetchRelationItems(csv)

            if (data && data.length > 0) {
                this.modalQuestionnaireFilter.roles = unionBy(this.modalQuestionnaireFilter.roles, data, 'id')
                this.successFeedback(this.i18nSelectThroughCsv, this.i18nSuccessfullyProcessed)
            } else {
                this.warningFeedback(this.i18nSelectThroughCsv, this.i18nNoRecordsFound)
            }

            this.$refs.roleCsvModal.hideModal()
        },
        async loadUsersByJson(csv) {
            const data = await this.$refs.userCsvModal.fetchRelationItems(csv)

            if (data && data.length > 0) {
                this.modalQuestionnaireFilter.users = unionBy(this.modalQuestionnaireFilter.users, data, 'id')
                this.successFeedback(this.i18nSelectThroughCsv, this.i18nSuccessfullyProcessed)
            } else {
                this.warningFeedback(this.i18nSelectThroughCsv, this.i18nNoRecordsFound)
            }

            this.$refs.userCsvModal.hideModal()
        },
        handleAfterClone() {
            this.$emit('refresh')
            this.resetValidation()
            this.$refs.formModal.hide()
        }
    },
}
</script>
