<template>
    <form-modal
        ref="formModal"
        :handle-open="resetValidation"
        :v="$v.modalFilterClone"
        :title="i18nTitleModal"
        :in-editing="true"
        size="lg"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nTitleModal"
                    :operation="'clone'"
                />
                <b-form-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nBusinessUnit"
                            class="required label-pdv"
                            label-for="modal-business-unit"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_business_unit')"
                        >
                            <multiSelectWithService
                                :id="'modal-business-unit'"
                                ref="businessUnitMultiselect"
                                v-model="modalFilterClone.business_unit_id"
                                :input-function="$v.modalFilterClone.business_unit_id.$touch"
                                :select-class="validationClass($v.modalFilterClone.business_unit_id)"
                                :service="'business-units'"
                                label="name"
                                :searchable="true"
                                :multiple="true"
                                :keep-open-on-select="true"
                                :limit="20"
                                :disabled="submitLoading"
                                :placeholder="i18nBusinessUnit"
                                :parameters="businessUnitsParameters"
                                :options-to-remove-list="[modalFilterClone.current_business_unit_id]"
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </form>
        </template>
        <template v-slot:footer>
            <div class="col text-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <add-button
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    :title="getI18n('COMMON.clone')"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import swalFeedback from '@src/mixins/swalFeedback'

import QuestionnaireFiltersService from '@src/services/QuestionnaireFiltersService.js'

import validationMixin from '@src/mixins/validation'

import multiSelectWithService from '@src/components/multiSelectWithService'
import FormModal from '@components/FormModal'
import filterVuetable from '@src/mixins/filterVuetable'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import AddButton from '@/src/components/AddButton'
import CancelButton from '@/src/components/CancelButton.vue'

const i18nCommon = 'COMMON'
const i18nKey = 'QUESTIONNAIRE_FILTERS'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        multiSelectWithService,
        AddButton,
        CancelButton,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        filterVuetable,
    ],
    data() {
        return {
            i18nCommon,
            i18nKey,
            submitLoading: false,
            modalFilterClone: {
                questionnaire_filter_id: 0,
                questionnaire_id: 0,
                current_business_unit_id: 0,
                business_unit_id: [],
            },
            businessUnitsParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
        }
    },
    validations: {
        modalFilterClone: {
            business_unit_id: {
                required,
                $each: {
                    id: {
                        required,
                    },
                }
            },
        },
    },

    computed: {
        i18nTitleModal() {
            return `${this.getI18n(i18nCommon, 'clone')} ${this.getI18n(i18nCommon, 'filter')}`
        },
        i18nBusinessUnit() {
            return this.getI18nModified({
                        prefix: 'BUSINESS_UNITS',
                        suffix: 'TITLES.business_unit',
                        modifier: 2,
                    })
        },
    },

    methods: {
        showModal(data) {
            this.modalFilterClone = {
                ...data,
                business_unit_id: [],
            }
            this.submitLoading = false
            this.$nextTick(() => this.$refs.formModal.show())
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        cleanModal() {
            this.replaceModalData({
                business_unit_id: []
            })
            this.resetValidation()
        },

        replaceModalData(data) {
            Object.assign(this.modalFilterClone, data)
        },

        handleCancel() {
            this.handleClose(true)
        },

        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },

        handleOk() {
            this.$v.modalFilterClone.$touch()
            if (!this.$v.modalFilterClone.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const data = {
                questionnaire_filter_id: this.modalFilterClone.questionnaire_filter_id,
                questionnaire_id: this.modalFilterClone.questionnaire_id,
                business_unit_id: this.modalFilterClone.business_unit_id.map((unit) => unit.id),
            }

            const operation = QuestionnaireFiltersService.replicate(data)

            const createResponse = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = createResponse.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('afterClone')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nTitleModal, 'created')
            } else if (statusCode === '422') {
                this.unsuccessfulOperation(createResponse.data.errors)
            } else {
                this.warningFeedback(this.i18nTitleModal, createResponse.data.message)
            }

            this.submitLoading = false
        },
        unsuccessfulOperation(errors) {
            const messages = Object.entries(errors)
            if (messages.length > 0) {
                const error = messages[0]
                if (error.length > 1 && error[0].split('.').length > 1 && error[1].length > 0) {
                    const businessUnit = this.modalFilterClone.business_unit_id[Number(error[0].split('.')[1])]
                    if (businessUnit) {
                        let message = error[1][0].replace(error[0], businessUnit.name)
                        message = message.replace(this.getI18n(i18nCommon, 'field').toLowerCase(), this.getI18n(i18nCommon, 'business_unit').toLowerCase())

                        this.warningFeedback(this.i18nTitleModal, message)
                        return
                    }
                }
            }

            this.negativeFeedback()
        },
    },
}
</script>
