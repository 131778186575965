<script>
export default {
    data() {
        return {
            frequenciesWithoutChildrens: [
                {
                    id: 'ANY_DAY',
                    label: this.getI18n('FREQUENCIES', 'ANY_DAY'),
                },
                {
                    id: 'ANY_DAY_OF_WEEK',
                    label: this.getI18n('FREQUENCIES', 'ANY_DAY_OF_WEEK'),
                },
                {
                    id: 'DAY_OF_WEEK_MONDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_MONDAY'),
                },
                {
                    id: 'DAY_OF_WEEK_TUESDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_TUESDAY'),
                },
                {
                    id: 'DAY_OF_WEEK_WEDNESDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_WEDNESDAY'),
                },
                {
                    id: 'DAY_OF_WEEK_THURSDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_THURSDAY'),
                },
                {
                    id: 'DAY_OF_WEEK_FRIDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_FRIDAY'),
                },
                {
                    id: 'DAY_OF_WEEK_SATURDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_SATURDAY'),
                },
                {
                    id: 'DAY_OF_WEEK_SUNDAY',
                    label: this.getI18n('FREQUENCIES', 'DAY_OF_WEEK_SUNDAY'),
                },
                {
                    id: 'ANY_WEEK_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'ANY_WEEK_OF_MONTH'),
                },
                {
                    id: 'FIRST_WEEK_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'FIRST_WEEK_OF_MONTH'),
                },
                {
                    id: 'SECOND_WEEK_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'SECOND_WEEK_OF_MONTH'),
                },
                {
                    id: 'THIRD_WEEK_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'THIRD_WEEK_OF_MONTH'),
                },
                {
                    id: 'FOURTH_WEEK_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'FOURTH_WEEK_OF_MONTH'),
                },
                {
                    id: 'FIFTH_WEEK_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'FIFTH_WEEK_OF_MONTH'),
                },
                {
                    id: 'ANY_MONTH',
                    label: this.getI18n('FREQUENCIES', 'ANY_MONTH'),
                },
                {
                    id: 'MONTH_JANUARY',
                    label: this.getI18n('FREQUENCIES', 'MONTH_JANUARY'),
                },
                {
                    id: 'MONTH_FEBRUARY',
                    label: this.getI18n('FREQUENCIES', 'MONTH_FEBRUARY'),
                },
                {
                    id: 'MONTH_MARCH',
                    label: this.getI18n('FREQUENCIES', 'MONTH_MARCH'),
                },
                {
                    id: 'MONTH_APRIL',
                    label: this.getI18n('FREQUENCIES', 'MONTH_APRIL'),
                },
                {
                    id: 'MONTH_MAY',
                    label: this.getI18n('FREQUENCIES', 'MONTH_MAY'),
                },
                {
                    id: 'MONTH_JUNE',
                    label: this.getI18n('FREQUENCIES', 'MONTH_JUNE'),
                },
                {
                    id: 'MONTH_JULY',
                    label: this.getI18n('FREQUENCIES', 'MONTH_JULY'),
                },
                {
                    id: 'MONTH_AUGUST',
                    label: this.getI18n('FREQUENCIES', 'MONTH_AUGUST'),
                },
                {
                    id: 'MONTH_SEPTEMBER',
                    label: this.getI18n('FREQUENCIES', 'MONTH_SEPTEMBER'),
                },
                {
                    id: 'MONTH_OCTOBER',
                    label: this.getI18n('FREQUENCIES', 'MONTH_OCTOBER'),
                },
                {
                    id: 'MONTH_NOVEMBER',
                    label: this.getI18n('FREQUENCIES', 'MONTH_NOVEMBER'),
                },
                {
                    id: 'MONTH_DECEMBER',
                    label: this.getI18n('FREQUENCIES', 'MONTH_DECEMBER'),
                },
                {
                    id: 'ANY_DAY_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'ANY_DAY_OF_MONTH'),
                },
                {
                    id: 'FIRST_DAY_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'FIRST_DAY_OF_MONTH'),
                },
                {
                    id: 'SECOND_DAY_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'SECOND_DAY_OF_MONTH'),
                },
                {
                    id: 'THIRD_DAY_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'THIRD_DAY_OF_MONTH'),
                },
                {
                    id: 'FOURTH_DAY_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'FOURTH_DAY_OF_MONTH'),
                },
                {
                    id: 'FIFTH_DAY_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'FIFTH_DAY_OF_MONTH'),
                },
                {
                    id: 'SIXTH_DAY_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'SIXTH_DAY_OF_MONTH'),
                },
                {
                    id: 'SEVENTH_DAY_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'SEVENTH_DAY_OF_MONTH'),
                },
                {
                    id: 'EIGHTH_DAY_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'EIGHTH_DAY_OF_MONTH'),
                },
                {
                    id: 'NINTH_DAY_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'NINTH_DAY_OF_MONTH'),
                },
                {
                    id: 'TENTH_DAY_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'TENTH_DAY_OF_MONTH'),
                },
                {
                    id: 'ELEVENTH_DAY_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'ELEVENTH_DAY_OF_MONTH'),
                },
                {
                    id: 'TWELFTH_DAY_OF_MONTH',
                    label: this.getI18n('FREQUENCIES', 'TWELFTH_DAY_OF_MONTH'),
                },
                {
                    id: 'THIRTEENTH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'THIRTEENTH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'FOURTEENTH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'FOURTEENTH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'FIFTEENTH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'FIFTEENTH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'SIXTEENTH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'SIXTEENTH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'SEVENTEENTH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'SEVENTEENTH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'EIGHTEENTH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'EIGHTEENTH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'NINETEENTH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'NINETEENTH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'TWENTIETH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'TWENTIETH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'TWENTY_FIRST_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'TWENTY_FIRST_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'TWENTY_SECOND_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'TWENTY_SECOND_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'TWENTY_THIRD_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'TWENTY_THIRD_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'TWENTY_FOURTH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'TWENTY_FOURTH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'TWENTY_FIFTH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'TWENTY_FIFTH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'TWENTY_SIXTH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'TWENTY_SIXTH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'TWENTY_SEVENTH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'TWENTY_SEVENTH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'TWENTY_EIGHTH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'TWENTY_EIGHTH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'TWENTY_NINTH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'TWENTY_NINTH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'THIRTIETH_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'THIRTIETH_DAY_OF_MONTH'
                    ),
                },
                {
                    id: 'THIRTY_FIRST_DAY_OF_MONTH',
                    label: this.getI18n(
                        'FREQUENCIES',
                        'THIRTY_FIRST_DAY_OF_MONTH'
                    ),
                },
            ],
        }
    },
}
</script>
