<template>
    <b-form-group
        class="required"
        :invalid-feedback="getI18n('ERROR_CODES.invalid_item')"
    >
        <b-row v-if="!disabled" class="mb-2">
            <div class="col-sm-6 form-inline">
            </div>
            <div class="col-sm-6">
                <div class="text-sm-right">
                    <add-button
                        v-if="$can('CREATE', 'QUESTIONNAIREITEM')"
                        :title="i18nNewQuestionnaireItem"
                        :disabled="busy"
                        @onClick="createItem"
                    />
                </div>
            </div>
        </b-row>
        <questionnaire-item-modal
            ref="questionnaireItemModal"
            :questionnaire-items="questionnaireItems"
            :modal="modalQuestionnaireItem"
            @refresh="refreshTable"
        />
        <grid-form-modal
            ref="gridChoicesModal"
            :modal="modalQuestionnaireItem"
            :items="modalQuestionnaireItem.choices"
            :fields="choiceChildrenFields"
            :title="
                getI18nModified({
                    prefix: 'QUESTIONS',
                    suffix: 'FIELDS.choice',
                    modifier: 2,
                })
            "
        />
        <b-skeleton-table
            v-if="busy"
            :rows="3"
            :columns="5"
            :table-props="{ hover: true, stickyHeader: true }"
        />
        <div v-else class="table-responsive table-pdv">
            <b-table-simple
                hover
                show-empty
                sticky-header
            >
                <b-thead>
                    <b-th v-if="!disabled">
                        <b-spinner
                            v-if="isPositionLoading"
                            small
                        />
                    </b-th>
                    <b-th v-if="!disabled" scope="col">
                        {{ getI18n('COMMON', 'order') }}
                    </b-th>
                    <b-th scope="col" width="60%">
                        {{ getI18n('QUESTIONS', 'TITLES.question') }}
                    </b-th>
                    <b-th>
                        {{ getI18n('QUESTIONS', 'FIELDS.subtype') }}
                    </b-th>
                    <b-th scope="col">
                        {{ i18nChoices }}
                    </b-th>
                    <b-th>
                        {{ getI18n('QUESTIONS', 'TITLES.multiple_answers_resume') }}
                    </b-th>
                    <b-th>
                        {{ getI18n('QUESTIONNAIRE_ITEMS', 'FIELDS.mandatory_answer') }}
                    </b-th>
                    <b-th>
                        {{ getI18n('QUESTIONNAIRE_ITEMS', 'FIELDS.finalize_task') }}
                    </b-th>
                    <b-th v-if="!disabled" />
                </b-thead>
                <draggable
                    v-model="questionnaireItems"
                    tag="tbody"
                    :class="{ [`table-cursor-grabbing`]: dragging === true }"
                    handle=".table-handle"
                    @change="saveMove"
                >
                    <template v-for="(item, index) in questionnaireItems">
                        <b-tr :key="item.id">
                            <b-td v-if="!disabled">
                                <div class="table-handle">
                                    <i class="fe-move"/>
                                </div>
                            </b-td>
                            <b-td v-if="!disabled">
                                {{ index + 1 }}
                            </b-td>
                            <b-td>
                                {{ item.question.question }}
                            </b-td>
                            <b-td>
                                {{ subtypeToText(item.question.subtype) }}
                            </b-td>
                            <b-td>
                                <count-table-button
                                    v-if="item.question.type === 'choice'"
                                    :value="item.choices.length"
                                    @onClick="openModalChoices(item)"
                                />
                            </b-td>
                            <b-td>
                                {{ booleanIcons(item.is_multiple) }}
                            </b-td>
                            <b-td>
                                {{ booleanIcons(item.is_required) }}
                            </b-td>
                            <b-td>
                                {{ booleanIcons(item.is_end) }}
                            </b-td>
                            <b-td v-if="!disabled">
                                <div class="text-right">
                                    <more-table-button @onClick="editItem(item)" />
                                </div>
                            </b-td>
                        </b-tr>
                    </template>
                </draggable>
            </b-table-simple>
        </div>
        <!-- <div v-else>
            <h4 class="text-center">
                {{ getI18n('ERROR_CODES', 'no_items_created') }}.
            </h4>
        </div> -->
    </b-form-group>
</template>

<script>
import questionnaireItemModal from '@src/router/views/questionnaires/questionnaireItemModal'
import draggable from 'vuedraggable'
import swalFeedback from '@src/mixins/swalFeedback'
import answerTypes from '@src/mixins/answerTypes'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import QuestionnaireItemsService from '@src/services/QuestionnaireItemsService'
import GridFormModal from '@src/components/GridFormModal.vue'
import AddButton from '@/src/components/AddLabelButton.vue'
import CountTableButton from '@/src/components/CountTableButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import choiceChildrenFields from '@/src/fields/childrens/choiceChildrenFields.vue'
import { debounce } from 'lodash'

const i18nKey = 'QUESTIONNAIRE_ITEMS'

export default {
    components: {
        questionnaireItemModal,
        draggable,
        GridFormModal,
        AddButton,
        CountTableButton,
        MoreTableButton,
    },
    mixins: [
        swalFeedback,
        answerTypes,
        vuetableFormatters,
        choiceChildrenFields
    ],
    props: {
        value: {
            type: Array,
            required: true,
        },
        questionnaireId: {
            type: Number,
            required: true,
        },
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            modalQuestionnaireItem: {
                id: 0,
                question: {
                    id: null,
                    type: '',
                    choices: [],
                },
                questionnaire_id: this.questionnaireId,
                question_id: null,
                previous_id: null,
                next_id: null,
                position: 0,
                label: '',
                is_required: true,
                is_end: false,
                choices: [],
                flux_choices: [],
                flux_booleans: [],
            },
            loadingEditItem: [],
            questionnaireItemIsEdit: false,
            fluxChoices: [],
            dragging: false,
            isPositionLoading: false,
            activeKey: {
                id: null,
                success: false,
            },
        }
    },
    computed: {
        questionnaireItems: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        i18nQuestionnnaireItem() {
            return this.getI18n(i18nKey, 'TITLES.questionnaire_item')
        },
        i18nNewQuestionnaireItem() {
            return this.getI18n(i18nKey, 'BUTTONS.new_questionnaire_item')
        },
        i18nChoices() {
            return this.getI18nModified({
                prefix: 'QUESTIONS',
                suffix: 'FIELDS.choice',
                modifier: 2,
            })
        },
    },
    watch: {
        questionnaireId(newValue) {
            this.modalQuestionnaireItem.questionnaire_id = newValue
        },
    },
    methods: {
        async syncFluxChoices(item) {
            const fluxChoices = item.flux_choices.filter(
                (el) => el.next_id !== 0
            )

            await QuestionnaireItemsService.syncFluxChoices(item.id, {
                flux_choices: fluxChoices,
            })
                .then((response) => {
                    this.successfulSync(item.id)
                    return response
                })
                .catch((error) => {
                    this.unsuccessfulSync(item.id)
                    return error
                })
        },
        async syncFluxBooleans(item) {
            const fluxBooleans = item.flux_booleans.filter(
                (el) => el.next_id !== 0
            )

            await QuestionnaireItemsService.syncFluxBooleans(item.id, {
                flux_booleans: fluxBooleans,
            })
                .then((response) => {
                    this.successfulSync(item.id)
                    return response
                })
                .catch((error) => {
                    this.unsuccessfulSync(item.id)
                    return error
                })
        },
        saveMove(evt) {
            this.$nextTick(() => {
                this.isPositionLoading = true
                this.savePositionsAndChoices()
            })
        },

        async savePositionsAndChoices() {
            const positionsResponse = await QuestionnaireItemsService.syncPositions(
                {
                    questionnaire_id: this.questionnaireId,
                    questionnaire_items: this.questionnaireItems,
                    with: [
                        'question',
                        'choices',
                        'fluxChoices',
                        'fluxBooleans',
                    ],
                    orderBy: 'position',
                }
            )
                .then((response) => response)
                .catch((error) => {
                    return error
                })

            const statusCode = positionsResponse.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.questionnaireItems = positionsResponse.data.data
                this.refreshTable()
            }

            if (statusCode === '422') {
                this.modalResponseErrors = positionsResponse.data.errors
            }

            this.isPositionLoading = false
        },

        isActiveAndSuccessful(i) {
            return this.activeKey.id === i && this.activeKey.success
        },

        isActiveAndUnsuccessful(i) {
            return this.activeKey.id === i && !this.activeKey.success
        },

        clearActiveKey: debounce(function () {
            this.activeKey = {
                id: null,
                success: false,
            }
        }, 1000),

        successfulSync(i) {
            this.activeKey.id = i
            this.activeKey.success = true
            this.clearActiveKey()
        },

        unsuccessfulSync(i) {
            this.activeKey.id = i
            this.activeKey.success = false
            this.clearActiveKey()
        },

        async refreshTable() {
            this.$emit('loading', true)
            await QuestionnaireItemsService.fetchAll({
                search: `questionnaire_id:${this.questionnaireId}`,
                with: ['question', 'choices', 'fluxChoices', 'fluxBooleans'],
                check_for_answers: true,
                orderBy: 'position',
                per_page: 999,
            }).then((response) => {
                this.questionnaireItems = response.data.data

                this.$emit('loading', false)
            })
        },

        createItem() {
            this.questionnaireItemIsEdit = true
            this.cleanModal()
            this.showModal(true)
        },
        editItem(data) {
            this.questionnaireItemIsEdit = false
            this.replaceModalData(data)
            this.showModal(false)
        },

        showModal(isEdit) {
            this.modalQuestionnaireItem.question.choices = this.modalQuestionnaireItem.choices

            this.$refs.questionnaireItemModal.showModal(isEdit)
        },

        cleanModal() {
            Object.assign(this.modalQuestionnaireItem, {
                id: 0,
                question: {
                    id: 0,
                    type: '',
                    choices: [],
                },
                questionnaire_id: this.questionnaireId,
                question_id: null,
                previous_id: null,
                next_id: null,
                position: this.value.length + 1,
                label: '',
                is_required: true,
                is_end: false,
                choices: [],
                flux_choices: [],
                flux_booleans: [],
            })
        },
        replaceModalData(data) {
            Object.assign(this.modalQuestionnaireItem, data)
        },

        openModalChoices(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridChoicesModal.showModal())
        },
    },
}
</script>

<style scoped>
#draggableItens .no-top-border {
    border-top: 0;
}
.operation-feedback-icon {
    right: -20px;
    bottom: 0;
}

.slide-fade-enter-active {
    transition: all 0.3s ease;
}
.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter {
    transform: translateX(10px);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.questionnaire-items-list {
    height: 616px;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
}

.choices-small-list {
    height: 190px;
    overflow: hidden;
}

.table td {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
